// @ts-nocheck
import React, { Fragment, useState } from "react";
import { InputMask } from 'primereact/inputmask';
import { Button } from 'primereact/button';
import styles from './signin.module.css'
import { Link, useHistory } from "react-router-dom";
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import styled from 'styled-components';
import { useStore } from '../stores/useStore';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const FormErrorMsg = styled.span`
 color: red;
 float:right;
 font-size:12px;
`
const Signin = () => {
    // const [mobile, setMobile] = useState("");
    // const [otp, setOtp] = useState("");
    const history = useHistory();
    const { waiterSessionStore } = useStore();
    const [errorMsg, setErrorMsg] = useState(null);
    const [mobile, setMobile] = useState<any>();

    window.OneSignal = window.OneSignal || [];
    const OneSignal = window.OneSignal;


    return (
        <Fragment>
            <div className={styles.signin}>
                <div className={styles.container}>
                    <Formik
                        initialValues={{ phone: "", otp: "" }}
                        // validationSchema={yup.object({
                        //     phone: yup.string()
                        //         .required('Required'),
                        //     otp: yup.string()
                        //         .required('Required'),
                        // })}
                        onSubmit={(values) => {
                            console.log(values, "login data")
                            waiterSessionStore.authenticate(mobile, values.otp)
                                .then(([success, message]: any) => {
                                    if (success) {
                                        setErrorMsg(null)
                                        let id = (message.restaurant_id).toString()
                                      
                                        OneSignal.push(() => {
                                                OneSignal.setExternalUserId(id)
                                                //   alert(`External id  :  ${id}`)
                                        })
                                        history.push('/')
                                        window.history.go(0)
                                    } else {
                                        setErrorMsg(message)
                                    }
                                })
                        }}>
                        {({ errors, touched }) => (
                            <Form>
                                <img className={styles.loginlogo} src="logo/purple bg.svg" alt="logo" />
                                <div className={styles.inputHold}>
                                    <p>Enter your phone number <span id="phone"
                                        style={{ display: "none", float: "right", fontSize: "14px" }}
                                        className="float-right">Required</span></p>
                                    <div className="p-inputgroup">
                                        {/* <span className="p-inputgroup-addon">
                                        <i className="pi pi-mobile"></i>
                                    </span> */}
                                        <div id="pinp" className="code">
                                            <Field as={PhoneInput} required type="tel" onlyCountries={['gb', 'in']}
                                                country={'gb'} value={mobile} onChange={(e: any) => {
                                                    if (e?.length <= 11) {
                                                        let p = document.getElementById('phone')
                                                        let pi = document.getElementById('pinp')
                                                        p.style.color = "red"
                                                        p.style.display = "initial"
                                                        pi.style.border = "1px solid red"
                                                    }
                                                    else {
                                                        let p = document.getElementById('phone')
                                                        let pi = document.getElementById('pinp')
                                                        p.style.display = "none"
                                                        pi.style.border = "none"

                                                    }
                                                    setMobile('+' + e)
                                                }}
                                                onBlur={(e: any) => {
                                                    console.log("on blur phone", e.target.value)
                                                    if (e.target.value?.length <= 3) {
                                                        let p = document.getElementById('phone')
                                                        let pi = document.getElementById('pinp')
                                                        p.style.color = "red"
                                                        p.style.display = "initial"
                                                        pi.style.border = "1px solid red"
                                                    }
                                                    else {
                                                        let p = document.getElementById('phone')
                                                        let pi = document.getElementById('pinp')
                                                        p.style.display = "none"
                                                        pi.style.border = "none"

                                                    }
                                                }}
                                                name="phone" className={(errors.phone && touched.phone ? styles.isInvalid : styles.input)} /></div>
                                        {/* <InputMask mask="+99 9999999999" value={mobile} onChange={(e) => { setMobile(e.value); }} className={styles.input} /> */}
                                    </div>
                                    <p>Enter 4 digit pin <ErrorMessage name='otp' component={FormErrorMsg} /></p>
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">
                                            <i className="pi pi-lock"></i>
                                        </span>
                                        <Field as={InputMask} mask="****" required name="otp" className={(errors.otp && touched.otp ? styles.isInvalid : styles.input)} />
                                    </div>
                                    <span className="errorMsg">{errorMsg} <span><Link to="/newPin">
                                        <h6 className={styles.forgotPin}>Forgot PIN?</h6>
                                    </Link></span></span>
                                    <Button type="submit" label="SIGN IN" className={styles.buttonOutlined} />
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </Fragment>
    )
}
export default Signin;