import axios from 'axios'
import { toast } from 'react-toastify';

const apiClient = axios.create({
    baseURL: process.env.REACT_APP_MAIN_API_BASE_URL,
    timeout: 150000,
    withCredentials: false,
    headers: {
    "Content-Type": "application/json",
    // 'Access-Control-Allow-Origin' : '*',
    // 'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    // "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, Authorization, Accept,charset,boundary,Content-Length"
    }
}
);

async function getAccessToken() {
    return localStorage.getItem('access');
}
export async function getRefreshToken() {
    let refresh = localStorage.getItem('refresh');

    console.log("sending refresh token : ",refresh)
    apiClient.post('/api/v1/refresh',{},
        {
            headers:{
                Authorization: 'Bearer ' + refresh,
            }
        }).then((res: any) => {
            console.log("refresh token data : ", res)
            // alert(res)
            localStorage.setItem('access', res.data.access_token)
            apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + getAccessToken();
            return res
        })
        .catch((err: any) => {
            let errorMsg = err.response ? err.response.status : err.message
            toast.error(errorMsg)
            console.log("refresh token error data : ", errorMsg)
            // alert(err)
            return err
        });
}

// Request interceptor for auth header

// apiClient.interceptors.request.use(async (config) => {
//     config.headers['Authorization'] = 'Bearer ' + await getAccessToken();
//     return config;
// });

apiClient.interceptors.response.use(undefined,
   function (error) {
    const originalRequest = error.config;


        if (error && error.response && error.response.status === 401 && !originalRequest._retry ) {
            // localStorage.clear();
            // sessionStorage.clear();
            originalRequest._retry = true;
           return apiClient.post('/api/v1/refresh',{},
           {
            headers:{
                Authorization: 'Bearer ' + localStorage.getItem('refresh'),
            }
               
           }).then((res: any) => {
               console.log("refresh token data : ", res)
               localStorage.setItem('access', res.data.access_token)
               apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + getAccessToken();
               window.location.reload();
               window.history.go(0)
               return apiClient(originalRequest);
           })
           .catch((err: any) => {
            let errorMsg = err.response ? err.response.data.detail : err.message

            toast.error(errorMsg)
            console.log("refresh token error data : ", errorMsg)
            return errorMsg
        });
        }
        return Promise.reject(error);
    }
)
export default apiClient;
