import React, { Fragment } from 'react';
import './upcomingReservationDetails.css';
import { Button } from 'primereact/button';
import { Link } from "react-router-dom";


const ReservationDetails = () => {
    return <Fragment>
        <div className='resDetails'>
            <div className='detailsHolder'>
                <div className='detailsCard'>
                    <h5>RESERVATION DETAILS</h5>
                    <span><i className="pi pi-times close"></i></span>
                    <hr />
                  <div className="name">
                  <h3>
                        Isha Mosley
                   </h3>
                    <p >T3,9:00 A.M,SAT,21 DEC 2020.
                   </p>
                  </div>
                  <div className="description">
                  <h5 >Reservation Notes</h5>
                    <p>Hey,Spoke with Stephanie over call,I have requested to<br/>
                    order a cake ,hope it will be available before the time!!
                       Thanks! </p>
                  </div>
                  <div className="status">
                  <h5 >Status</h5>
                    <button className=''  style={{fontSize:'14px',textAlign:'initial',color:'#FDFEFF'}}>Not Confirmed <i className="pi pi-play" style={{color:'white',marginLeft:'175px',marginTop:'5px',fontSize:'9px'}}></i></button>
                  </div>
                  <h3>Tags</h3>

                  <div className="tags">
                  <p>ADD MORE</p>
                        <h5 style={{color:'#FDFEFF'}}>VIP</h5>
                        <h5 style={{color:'#FDFEFF'}}>BDP</h5>
                  </div>
                  <hr/>

                  <div className="icons">
                  <i className="pi pi-phone icon-a"></i>
                        <i className="pi pi-comment icon-a"></i>
                        <i className="pi pi-trash icon-a"></i>
                  </div>

                  <div className="cr">

<p >Cancel Reservation</p>
<p >Reset</p>
</div>



                </div>
            </div>
        </div>
    </Fragment>
}

export default ReservationDetails;