// @ts-nocheck
import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import moment from 'moment';
import './waiterProfile.scss';
import { useStore } from "../stores/useStore";

const WaiterProfile = () => {
    const [waiter, setWaiter] = useState<any>();
    const { waiterSessionStore } = useStore();
    const history = useHistory();

   

    useEffect(() => {
        waiterSessionStore.waiterDetails()
            .then(([success, user]: any) => {
                if (success) {
                    setWaiter(user.data)
                    console.log(user.data)
                }
                else console.log("error : ", user)
            })
        let utc = moment("10:00:00 am", "hh:mm:ss a").format('YYYY-MM-DD HH:mm:ss')
        console.log("UTC time : ", utc);
        console.log("local time : ", moment(utc).local().format('hh:mm a'));
    }, [])

    window.OneSignal = window.OneSignal || [];
    const OneSignal = window.OneSignal;

    return <Fragment>
        <div className="Profile">
            <img src="/images/waiterP.svg" alt="waiter" />
            <div style={{ padding: "40px 0 0" }}>
                <h4>Personal Information</h4>
                <div className="Details">
                    <div>
                        <h5>First Name</h5>
                        <p>{waiter?.first_name}</p>
                    </div>
                    <div>
                        <h5>Last Name</h5>
                        <p>{waiter?.last_name}</p>
                    </div>
                    <div>
                        <h5>Restaurant Details</h5>
                        <p>{localStorage.getItem("rest_name")}</p>
                    </div>
                    <div>
                        <h5>Designation</h5>
                        <p>Waiter</p>
                    </div>
                    <div>
                        <h5>Phone Number</h5>
                        <p>{waiter?.mobile}</p>
                    </div>
                </div>
                <div style={{ display: "flex", columnGap: "30px" }}>
                    <Link to="/changePin">
                        <h3>Change Pin</h3>
                    </Link>
                    <h3 onClick={() => {
                        OneSignal.push(()=>{
                            OneSignal.removeExternalUserId();
                        }); waiterSessionStore.logout(); history.push('/signin');
                    }}><i className="fas fa-sign-out-alt"></i> LOGOUT</h3>
                </div>

            </div>
        </div>
    </Fragment>
}
export default WaiterProfile