import './App.css';
import { Link, Route } from 'react-router-dom';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import reservationStatus from './Pages/reservationStatus';
import queueReminder from './Pages/queueReminder';
import popUp4 from './Pages/upcomingReservationStatus';
import popUp5 from './Pages/seatedReservationStatus';
import popUp3 from './Pages/seatedReservartionDetails';
import reservationCancelled from './Pages/reservationCancelled';
import reservationDetails from './Pages/upcomingReservationDetails'
import Header from "./Components/header/index";
import Floor from "./Components/floor/index";
import "./Components/main/styles.scss";
import { Fragment, useEffect, useState } from 'react';
import GuestBook from "./Pages/guestBook";
import TapAndBook from "./Pages/tapAndBook";
import WaiterProfile from './Pages/waiterProfile'
import { Switch, useHistory } from 'react-router';
import { useStore } from './stores/useStore';
import { Dialog } from 'primereact/dialog';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { InputNumber } from 'primereact/inputnumber';
import { InputMask } from 'primereact/inputmask';
import { InputText } from "primereact/inputtext";
import OtpInput from "react-otp-input";
import styled from 'styled-components';
import * as yup from 'yup';
import moment from "moment";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import holidayimg from './holiday.svg'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Loader from './Components/Loader/loader'

const FormErrorMsg = styled.span`
 color: red;
 float:right;
 font-size:12px;
`
toast.configure()
const App = () => {
  const [custData, setCustLimit] = useState()
  const { restaurantSessionStore } = useStore();
  const [loader, setLoader] = useState(false);

  window.OneSignal = window.OneSignal || [];
  const OneSignal = window.OneSignal;

  useEffect(() => {
    OneSignal.push(() => {
      OneSignal.init(
        {
          appId: process.env.REACT_APP_ONESIGNAL_APP_ID,
        },
        // OneSignal.setExternalUserId(localStorage.getItem('rest_id')),
        //Automatically subscribe to the new_app_version tag
        // OneSignal.sendTag("new_app_version", `MTF_WAITER${localStorage.getItem('rest_id')}`, tagsSent => {
        //   // Callback called when tag has finished sending
        //   console.log('new_app_version TAG SENT', tagsSent);
        // })
        console.log("OneSignal initialized in app.js")
      );
    });

    restaurantSessionStore.getRestaurantSettings()
      .then(([success, data]) => {
        if (success) {
          // setData(data.items);
          setCustLimit(data.restaurant_settings.party_size_limit)
          console.log("settings data", data.restaurant_settings.party_size_limit);

        } else {
          // setErrorMsg(data)
          console.log("error",)

        }
      })
  }, [])


  const [dialog1, setDialog1] = useState(false);
  const [from, setFrom] = useState("");

  const onHide = () => {
    setDialog1(false);
  }

  const [guestCount, setGuestCount] = useState(1);
  const GetGuest = (e) => {
    if (e => 1) {
      setGuestCount(e)
    }
  }
  const [note, setNote] = useState("");
  const SubmitValues = async (values) => {
    setLoader(true)
    let valu =
    {
      first_name: values.fname,
      last_name: values.lname,
      email: values.email,
      mobile: values.phone,
      rest_id: localStorage.getItem('rest_id'),
      booked_date: moment(sessionStorage.getItem('selectedDate')).format('YYYY-MM-DD'),
      no_of_guests: guestCount,
      reserved_from: moment(from, "hh:mm a").format('HH:mm:ss'),
      note: note,
    }
    await restaurantSessionStore.quickbook(valu)
      .then(([success, message]) => {
        if (success) {
          setGuestCount(1);
          setFrom("");
          setMobile("");
          setOtp2(false);
          setHideotp(false);
          toast.success("Reservation Created Successfully")
    setLoader(false)
    // Floor.Reservations();
    // TapAndBook.floorTablesDetails();
          console.log("booking created : ", message);
        } else {
          setFrom("");
          setGuestCount(1);
          setMobile("");
          setOtp2(false);
          setHideotp(false);
          toast.error(message)
    setLoader(false)
          console.log("not working : ", message)
        }
      })
    console.log(valu);
    onHide();
  }
  const [mobile, setMobile] = useState("");

  const getOtp = () => {
    if (mobile.length === 13) {
      console.log("otp mobile", mobile)
      const num = mobile.replace("+", "%2B")
      console.log("otp mobile", num)
      restaurantSessionStore.getOtp(num)
        .then(([success, message]) => {
          if (success) {
            console.log("otp : ", message.data);
          } else {
            console.log("otp : ", message)
          }
        })
    }
  }

  const [otp2, setOtp2] = useState(false);
  const [add2, setAdd2] = useState(true);
  const [hideotp, setHideotp] = useState(false);
  const verifyOtp = (e) => {
    setOtp2(e);
    console.log(e)
    if (e.length === 6) {
      const num = mobile.replace("+", "%2B")
      restaurantSessionStore.verifyOtp(num, e)
        .then(([success, message]) => {
          if (success) {
            setAdd2(false);
            setHideotp(false)
            console.log("otp verification : ", message);
          } else {
            console.log("otp verification error : ", message)

          }
        })
    }
  }

  const [showHoliday, SetShowHoliday] = useState(true);
  const [showHoliday2, SetShowHoliday2] = useState(true);
  const [time, settime] = useState({});

  //Block reservation based on holiday

  function TrackHoliday() {
    SetShowHoliday(true);
    SetShowHoliday2(true);
    settime(false);
    restaurantSessionStore.holidaysList()
      .then(([success, message]) => {
        if (success) {
          // setholiday(message.filter(hday=>hday.is_deleted === false))

          //Filtering deleted holidays
          message.filter(hday => hday.is_deleted === false).map((holiday) => {
            //holiday start and end date
            var startDate = moment(holiday.start_date, "YYYY-MM-DD[T]HH:mm:ss")
            // .format("DD-MM-YYYY");
            var endDate = moment(holiday.end_date, "YYYY-MM-DD[T]HH:mm:ss")
            // .format("DD-MM-YYYY");

            //holiday start and end time
            var startT = moment(holiday.closed_from, 'HH:mm:ss');
            var endT = moment(holiday.closed_to, 'HH:mm:ss');

            // startDate = moment(startDate).set({ "hour": startT.hours(), "minute": startT.minutes() })
            // endDate = moment(endDate).set({ "hour": endT.hours(), "minute": endT.minutes() })

            // if(!holiday.is_closed_all_day){
            //   endDate = moment(endDate).add(1,'day')
            // }

            var today = moment(sessionStorage.getItem('selectedDate'), "YYYY-MM-DD")
            // .format("YYYY-MM-DD[T]HH:mm:ss");
            today = moment(today)
            // .set({ "hour": moment().hours(), "minute": moment().minutes() })
            console.log(startDate, endDate, today, moment(today) >= moment(startDate), moment(today) <= moment(endDate))

            //checking for holiday
            if (moment(today) >= moment(startDate) && moment(today) <= moment(endDate)) {
              //checking if holiday is for full day
              if (holiday.is_closed_all_day) {
                SetShowHoliday(false);
                console.log("full day")
              }
              else {
                console.log("partial day")
                SetShowHoliday2(false);
                settime({ from: holiday.closed_from, to: holiday.closed_to })
              }
              //checking for holiday time slot
              // else if (moment(currentTime)>=moment(startDate,) && moment(endDate)<=moment(endT)) {
              //   SetShowHoliday(false);
              // }
            }
            return true
          });
        } else {
          console.log("Holidays error : ", message);
        }
      })
  }
  useEffect(() => {
    TrackHoliday();
  }, [])

  const partialHday = async () => {
    // SetShowHoliday2(false);
    if (time.from && time.to) {
      await SetShowHoliday2(false);
    }
  }


  return <Fragment>
      <Loader setLoader={setLoader} visible={loader} />

    <div style={{ overflow: "hidden" }}>
      <Header TrackHoliday={TrackHoliday} />
      <div className="Holidayshow" style={{ display: showHoliday2 ? "none" : "grid" }} >
        <div className="holidayCard">
          <span style={{ transform: "translate(125px,15px)" }} onClick={() => SetShowHoliday2(true)} ><i className="pi pi-times close"></i></span>
          {/* <h1>Holiday</h1> */}
          <img src={holidayimg} alt="holiday" />
          {/* <p>Select another date to see bookings</p> */}
          <p>Partial holiday !</p>
          <p style={{ textTransform: "uppercase" }}>{moment(time?.from, 'HH:mm:ss')?.format("hh:mm a")} - {moment(time?.to, 'HH:mm:ss')?.format("hh:mm a")}</p>
        </div>
      </div>
    </div>
    <div className="grid-container">
      <div className="side-nav">
        <ul>
          <li onClick={() => {
            partialHday()
            setDialog1(true)
          }}>
            <i style={{ transform: "rotate(20deg)" }} className="fas fa-bolt">&nbsp;</i>
            <p>QUICK BOOK</p>
          </li>
          <li onClick={() => {
            partialHday()
            // window.history.go(0)
          }}>
            <Link to="/tap-to-book">
              <i className="fas fa-book"></i>
              <p>TAP &amp; BOOK</p>
            </Link>
          </li>
          <li onClick={() => window.history.go(0)}>
            <Link to="/">
              <i className="fas fa-boxes"></i>
              <p >FLOOR</p>
            </Link>
          </li>
          <li onClick={() => { window.history.go(0) }}>
            <Link to="/guest-book">
              <i className="fas fa-user-circle"></i>
              <p>GUEST BOOK</p>
            </Link>
          </li>
          {/* <li>
                <i className="fas fa-cog"></i>
                <p>SETTINGS</p>
              </li> */}


          <img className="logo" src="logo/logo.svg" alt="logo" />
        </ul>
      </div>
      <div className="main-content">
        <Switch>
          <Route exact path="/">
            <Floor />
          </Route>
          <Route exact path="/reservation-details" component={reservationDetails} />
          <Route exact path="/reservation-status" component={reservationStatus} />
          <Route exact path="/queue-reminder" component={queueReminder} />
          <Route exact path="/popup4" component={popUp4} />
          <Route exact path="/popup5" component={popUp5} />
          <Route exact path="/popup3" component={popUp3} />
          <Route exact path="/reservation-cancelled" component={reservationCancelled} />
          <Route exact path="/guest-book" component={GuestBook} />
          <Route exact path="/tap-to-book">
            <TapAndBook />
          </Route>
          <Route exact path="/waiter-profile" component={WaiterProfile} />
        </Switch>
      </div>
      <div className="Holidayshow2" style={{ display: showHoliday ? "none" : "grid" }} >
        <div className="holidayCard">
          {/* <h1>Holiday</h1> */}
          <img src={holidayimg} alt="holiday" />
          <p>Select another date to see bookings</p>
        </div>
      </div>

    </div>

    <Dialog visible={dialog1} position="center" style={{ width: "350px", height: "auto" }} onHide={() => onHide()}>
      <Formik
        initialValues={{ fname: '', lname: '', email: '', phone: "", walkin: guestCount, fromtime: from }}
        validationSchema={yup.object({
          fname: yup.string()
            .required('Required'),
          lname: yup.string()
            .required('Required'),
          email: yup.string().email('Must be a valid email').max(255).required('Email is required'),

          // phone: yup.string()
          //     .required('Required'),
          // fromtime: yup.string()
          // .required('Required'),
        })}
        onSubmit={(values) => {
          values.phone = mobile;
          console.log(values); SubmitValues(values);
        }}>
        {({ errors, status, touched }) => (
          <Form autoComplete="off">
            <div className="walkingQueue">
              <div className="queueForm">
                <div className='queueOne'>
                  <h5 style={{ marginBottom: '10px', color: '#510AEA' }}>QUICK BOOKING &nbsp;<span onClick={() => { onHide(); setGuestCount(1); setFrom(""); setMobile(""); }}><i className="pi pi-times close"></i></span>
                  </h5>
                  <hr />
                  <br />
                  <label >First Name<ErrorMessage name='fname' component={FormErrorMsg} /></label>
                  <div className="p-inputgroup hndl-mr">
                    <Field style={{ marginTop: '5px', marginBottom: '15px' }} as={InputText} name="fname" className={(errors.fname && touched.fname ? ' is-invalid' : 'p-inputgroup hndl-mr')} />
                  </div>
                  <label >Last Name<ErrorMessage name='lname' component={FormErrorMsg} /></label>
                  <div className="p-inputgroup hndl-mr">
                    <Field style={{ marginTop: '5px', marginBottom: '15px' }} as={InputText} name="lname" className={(errors.lname && touched.lname ? ' is-invalid' : 'p-inputgroup hndl-mr')} />
                  </div>

                  <label >Email<ErrorMessage name='email' component={FormErrorMsg} /></label>
                  <div className="p-inputgroup hndl-mr">
                    <Field required style={{ marginTop: '5px', marginBottom: '15px' }} as={InputText} name="email" className={(errors.email && touched.email ? ' is-invalid' : 'p-inputgroup hndl-mr')} />
                  </div>
                  <label style={{ marginTop: '15px' }} >Number of Guests<ErrorMessage name='walkin' component={FormErrorMsg} /></label>
                  <div className="p-inputgroup hndl-mr">
                    <InputNumber style={{ marginTop: '10px', marginBottom: '15px' }} min={1} max={custData} id="horizontal" name="walkin" className="numinc" showButtons buttonLayout="horizontal"
                      decrementButtonClassName="p-inpnumber-button" incrementButtonClassName="p-inpnumber-button" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" value={guestCount} onChange={(e) => GetGuest(e.value)} />
                    {/* <Field  style={{marginTop:'5px',marginBottom:'10px'}} as={InputText} name="name"  className={ (errors.name && touched.name ? ' is-invalid' : 'p-inputgroup hndl-mr') } /> */}
                  </div>
                  <div style={{ display: "flex", columnGap: "20px" }}>
                    <div>
                      <label>From</label><br />
                      <Field as={InputMask} required mask="99:99 aa" placeholder="Ex: 01:00 AM" name="fromtime" value={from} onChange={(e) => { setFrom(e.value) }} />
                    </div>
                    {/* <div>
                                            <label>To</label><br />
                                            <Calendar id="time12" name="totime" inline value={to} onChange={(e: any) => setTo(e.value)} timeOnly hourFormat="12" />
                                        </div> */}
                  </div>
                  <br />
                  <label >Phone Number<span id="phone"
                    style={{ display: "none", float: "right", fontSize: "14px" }}
                    className="float-right">Required</span></label>
                  <div id="pinp" className="p-inputgroup gotp hndl-mr  code">
                    <Field value={mobile} onChange={(e) => {
                      if (e?.length <= 11) {
                        let p = document.getElementById('phone')
                        let pi = document.getElementById('pinp')
                        p.style.color = "red"
                        p.style.display = "initial"
                        pi.style.border = "1px solid red"
                      }
                      else {
                        let p = document.getElementById('phone')
                        let pi = document.getElementById('pinp')
                        p.style.display = "none"
                        pi.style.border = "none"

                      }


                      setMobile('+' + e)
                      // if (mobile.length === 13) {
                      //   setHideotp(true)
                      // }
                      // else setHideotp(false)
                    }}
                      onBlur={(e: any) => {
                        console.log("on blur phone", e.target.value)
                        if (e.target.value?.length <= 3) {
                          let p = document.getElementById('phone')
                          let pi = document.getElementById('pinp')
                          p.style.color = "red"
                          p.style.display = "initial"
                          pi.style.border = "1px solid red"
                        }
                        else {
                          let p = document.getElementById('phone')
                          let pi = document.getElementById('pinp')
                          p.style.display = "none"
                          pi.style.border = "none"

                        }
                      }}
                      required onlyCountries={['gb', 'in']}
                      country={'gb'} as={PhoneInput} name="phone" className={(errors.phone && touched.phone ? ' is-invalid' : 'input getotp')} />
                    {/* {hideotp?
                      <span onClick={getOtp} className='p-inputgroup-addon'>
                          Get OTP
                      </span>:null} 
                    */}
                  </div>
                  <br/>
                  <label>Note</label>
                  <br />
                  <textarea className="textarea" onChange={(e) => setNote(e.target.value)}></textarea>

                  {/* {hideotp?<>
                                    <label style={{ marginTop: '15px' }}>OTP</label>
                                    <div className="p-inputgroup hndl-mr">
                                        <Field
                                            as={OtpInput}
                                            value={otp2}
                                            onChange={(e) => { verifyOtp(e) }}
                                            numInputs={6}
                                            maxLimit={6}
                                            // className={(errors.otp && touched.otp ? ' is-invalidinp' : 'otpField')}
                                            className='otpField'
                                            separator={<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>}
                                        />
                                    </div></>:null
                                    } */}
                  <input
                    // disabled={add2}
                    type="submit" value="Add" className='walkin-add-btn' />
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Dialog>
  </Fragment>

}
export default App;
