import React, { Fragment } from 'react';
import "./reservationCancelled.css";

const ReservationCancelled = () => {
    return <Fragment>
        <div className="resCancelled">
            <div className="cancelDetails">
            <i className="pi pi-times cancel-close" style={{marginLeft:'10px'}}></i>
<h4 style={{fontWeight:'600',textAlign:'center'}}>Reservation Cancelled</h4>
<p style={{fontSize:'12px',textAlign:"center"}}>Undo</p>

            </div>


        </div>

    </Fragment>}


export default ReservationCancelled;