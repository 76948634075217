import React, { useState, useEffect } from "react";
import moment from 'moment';
import Footer from "./footer.svg";
import Rectangle from "./rectangle.svg";
import Square from "./square.svg";
import Circle from "./circle.svg";
import Left from "./Left.svg";
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import styled from 'styled-components';
import { InputNumber } from 'primereact/inputnumber';
import "./styles.scss";
import { Dialog } from 'primereact/dialog';
import { InputText } from "primereact/inputtext";
import { InputMask } from 'primereact/inputmask';
import { Dropdown } from 'primereact/dropdown';
import OtpInput from "react-otp-input";
import { Chips } from 'primereact/chips';
import { useStore } from "../../stores/useStore";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Loader from '../Loader/loader'

const FormErrorMsg = styled.span`
 color: red;
 float:right;
 font-size:12px;
`
toast.configure()
const Floor = (props) => {
  const [errorMsg, setErrorMsg] = useState(null)
  const [resData, setResData] = useState([])
  const [walkinData, setWalkinData] = useState([])
  const [data, setData] = useState([])
  const { restaurantSessionStore } = useStore();
  const [custData, setCustLimit] = useState()
  const [loader, setLoader] = useState(false);


  const Reservations = () => {
    if (sessionStorage.getItem("selectedDate")) {
      restaurantSessionStore.getReservationList(sessionStorage.getItem("selectedDate"))
        .then(async ([success, data]) => {
          if (success) {
            setResData(data);
            const data2 = data?.filter(s => { return (s.reservation_status.reservation_status !== ("Vacant") && s.reservation_status.reservation_status !== ("Occupied")) })
            await setUpcomming(data2)
            console.log("reservation data", data);

          } else {
            setErrorMsg(data)
            console.log("reservation error", data);
          }
        })
    }
  }

  const Walkins = () => {
    restaurantSessionStore.getWalkinsList(sessionStorage.getItem("selectedDate"))
      .then(([success, data]) => {
        if (success) {
          const walkins = data.filter(walkin => walkin.is_deleted === false)
          setWalkinData(walkins);
          console.log("walkins data", data);

        } else {
          setErrorMsg(data)
        }
      })
  }
  const [walkinOptn, setWalkinOptn] = useState(true)
  const deleteWalkin = (id) => {
    console.log(" walkin delete clicked")
    restaurantSessionStore.deleteWalkin(id)
      .then(([success, data]) => {
        if (success) {
          Walkins();
          console.log("walkin deleted data", data.items);
        } else {
          setErrorMsg(data)
        }
      })
  }

  // const resByStatus = async () => {
  //   let date = sessionStorage.getItem("selectedDate")
  //   const Today = moment().format('yy-MM-DD')
  //   console.log("Today date : ", Today)
  //   console.log("Sending date : ", date)
  //   if (date !== Today) {
  //     await restaurantSessionStore.getReservationByStatus(date)
  //       .then(([success, data]) => {
  //         if (success) {
  //           setAllReservations(data)
  //           console.log("reservation by status", data);
  //           const data2 = data?.filter(s => { return (s.reservation_status.reservation_status !== ("Vacant") && s.reservation_status.reservation_status !== ("Occupied")) })
  //           setUpcomming(data2)
  //         } else {
  //           setErrorMsg(data)
  //         }
  //       })
  //   }
  //   else {
  //     await restaurantSessionStore.getReservationByStatus(date)
  //       .then(([success, data]) => {
  //         if (success) {
  //           setAllReservations(data)
  //           console.log("reservation by status", data);
  //           const data2 = data?.filter(s => { return (s.reservation_status.reservation_status !== ("Vacant") && s.reservation_status.reservation_status !== ("Occupied")) })
  //           setUpcomming(data2)
  //         } else {
  //           setErrorMsg(data)
  //         }
  //       })
  //   }
  // }


  useEffect(() => {
    Reservations();
    Walkins();
    restaurantSessionStore.getGuestList()
      .then(([success, data]) => {
        if (success) {
          setData(data.items);
          console.log("guestbook data", data.items);

        } else {
          setErrorMsg(data)
        }
      })

    restaurantSessionStore.getRestaurantSettings()
      .then(([success, data]) => {
        if (success) {
          // setData(data.items);
          setCustLimit(data.restaurant_settings.party_size_limit)
          console.log("settings data", data.restaurant_settings.party_size_limit);

        } else {
          setErrorMsg(data)
        }
      })
  }, [])

  const [blockAdd, setBlockAdd] = useState(true);
  const [walkinmsg, setWalkinmsg] = useState("");
  const addWalkin = (name) => {
    setLoader(true)
    const walkinData = {
      "first_name": name.fname,
      "last_name": name.lname,
      "no_of_guests": guestCount,
      "mobile": mobile,
      "booked_date": moment(sessionStorage.getItem("selectedDate"), 'yy-MM-DD HH:mm:ss').format('yy-MM-DD'),
      "rest_id": localStorage.getItem('rest_id')
    }
    restaurantSessionStore.createWalkins(walkinData)
      .then(([success, data]) => {
        if (success) {
          toast.success("Walk-in Added Successfully")
          console.log("walkin success data", data);
          setGuestCount(0);
          setMobile("");
          setOtp2(false);
          setMobile("");
          setGuestCount(1);
          setOtp2(false);
          onHide();
          Walkins();
          setHideotp(false)
          setLoader(false)


        } else {

          toast.error(data)
          console.log("walkin error data : ", data)
          setErrorMsg(data)
          setWalkinmsg(data)
          setHideotp(false)
          setLoader(false)

          setTimeout(() => {
            setWalkinmsg("")
          }, 6000);
        }
      })
    console.log("walkin data : ", walkinData)

  }

  const getOtp = () => {
    if (mobile.length === 13) {
      console.log("otp mobile", mobile)
      const num = mobile.replace("+", "%2B")
      console.log("otp mobile", num)
      restaurantSessionStore.getOtp(num)
        .then(([success, message]) => {
          if (success) {
            console.log("otp : ", message.data);
          } else {
            console.log("otp : ", message)
          }
        })
    }
  }

  const [otp2, setOtp2] = useState(false);
  const [hideotp, setHideotp] = useState(false);

  const verifyOtp = (e) => {
    setOtp2(e);
    console.log(e)
    if (e.length === 6) {
      const num = mobile.replace("+", "%2B")
      restaurantSessionStore.verifyOtp(num, e)
        .then(([success, message]) => {
          if (success) {
            setBlockAdd(false)
            setHideotp(false)
            console.log("otp verification : ", message);
          } else {
            console.log("otp verification error : ", message)

          }
        })
    }
  }


  //side nav bar
  const [show, setShow] = useState(false);
  const [chips, setChips] = useState([]);
  const [dialog, setDialog] = useState(false);
  const [walkindialog, setwalkinDialog] = useState(false);
  const [dialog1, setDialog1] = useState(false);
  const [dialog2, setDialog2] = useState(false);
  const [dialog3, setDialog3] = useState(false);
  const [mobile, setMobile] = useState("");
  const [selectedShift, setSelectedShift] = useState(null);
  const [selectedFloor, setSelectedFloor] = useState(null);
  const [guestCount, setGuestCount] = useState(1);

  const Guest = (e) => {
    const count = e.value;
    if (count > 0) {
      setGuestCount(count);
    }
  }

  const onWalkinDialog = () => {
    setDialog1(true)
  }

  const [floors, setFloors] = useState([]);
  const [shifts, setShifts] = useState([]);

  const floorList = () => {
    restaurantSessionStore.floorList()
      .then(([success, data]) => {
        if (success) {
          let floors = data.floors_list.filter((dlt) => dlt.is_deleted === false)
          setFloors(floors)
          setSelectedFloor(floors[0])

          console.log("floor list success : ", floors)

        } else {
          console.log("floor list error : ", data)
        }
      })
  }
  const [cDay, setCDay] = useState('')
  const shiftList = () => {
    restaurantSessionStore.shiftList()
      .then(([success, data]) => {
        if (success) {
          let currentDay = sessionStorage.getItem('selectedDate')
          currentDay = moment(currentDay).format('dddd')
          setCDay(currentDay)
          console.log(currentDay)
          let shifts = data.filter((dlt) => dlt.is_deleted === false)
          shifts = shifts.filter(days => {
            if (days.days_applicable.includes(currentDay)) {
              return days
            }
            return
          })

          setShifts(shifts)
          setSelectedShift(shifts[0])
          console.log("shift list success : ", shifts)

        } else {
          console.log("shift list error : ", data)
        }
      })
  }
  const onShiftchange = (e) => {
    setSelectedShift(e.value);
    console.log("shift selected", e)
  }
  const onFloorChange = (e) => {
    setSelectedFloor(e.value);
  }

  // useEffect(() => {
  //   const loaddata = () => {
  //     setSelectedShift(shifts[0])
  //     setSelectedFloor(floors[0])

  //   }
  //   loaddata();
  // })

  const selectedDate = sessionStorage.getItem("selectedDate");
  const [uItems, setUiItems] = useState([]);

  const floorPlanData = () => {
    const data = {
      "floor_id": selectedFloor?.floor_id,
      "shift_id": selectedShift?.id,
      "date": moment(selectedDate).format('yy-MM-DD')
    }
    console.log(data, selectedFloor?.name, selectedShift?.name)
    restaurantSessionStore.floorPlanData(data)
      .then(([success, data]) => {
        if (success) {
          let tables = data.filter(tbl => tbl.floor_plan_data.is_deleted === false)
          setUiItems(tables)
          console.log("floorPlanData success : ", data, tables)
        } else {
          console.log("floorPlanData error : ", data)
        }
      })
  }


  useEffect(() => {
    shiftList();
    floorList();
    floorPlanData();
    setTimeout(() => {
      let ck2 = document?.getElementById('upcoming')
      ck2?.click();
    }, 1000)
    // setTimeout(() => {
    //   let ck = document.getElementById('floor')
    //   ck.click();
    // }, 90);
    if (cDay !== sessionStorage.getItem('selectedDate')) {
      shiftList();
    }
  }, [])



  const [userdtls, setUserdtls] = useState();
  const [userdtls2, setUserdtls2] = useState();
  const [walkinuser, setWalkinUser] = useState();

  const walkinStatus = (user) => {
    if (user.reservation_status.reservation_status !== "Waiting") {
      setwalkinDialog(true);
    }
    console.log("clicked walkin : ", user)
    setWalkinUser(user);
  }

  const onupcomingclick = (name) => {
    console.log("clicked user", name)
    setUserdtls(name)
    setDialog(true);
    setUResDetails(true);
    setUResStatus(false);
  }

  const onseatedClick = (name) => {
    setUserdtls2(name)
    setSResDetails(true)
    setDialog2(true)
    setSResStatus(false);
    setSQReminder(false);
    setQResStatus(false)

  }
  const onHide = () => {
    setDialog(false);
    setDialog1(false);
    setDialog2(false);
    setwalkinDialog(false);
  }


  const [showWalkins, setWalkinsList] = React.useState(false);
  const [showUResDetails, setUResDetails] = React.useState(true);
  const [showUResStatus, setUResStatus] = React.useState(false);
  const [showSubBtn, setSubBtns] = React.useState(true);
  const [showSResDetails, setSResDetails] = React.useState(true);
  const [showSResStatus, setSResStatus] = React.useState(true);
  const [showSQReminder, setSQReminder] = React.useState(true);
  const [showQResStatus, setQResStatus] = React.useState(true);
  // const [allReservations, setAllReservations] = useState();
  const [upcomming, setUpcomming] = useState();
  const [statusAll, setStatusAll] = useState();
  const [seatedStatus, setSeatedStatus] = useState();
  const [reservationStatus, setReservationStatus] = useState(true)
  const [allReservation, setAllReservation] = useState(false)
  const [seatedShow, setSeatedShow] = useState(false)

  const seated = () => {
    const d1 = resData
    const data = d1?.filter(s => s.reservation_status.reservation_status === "Occupied")
    setSeatedStatus(data);
    setReservationStatus(false)
    setAllReservation(false)
    setSeatedShow(true)
  }

  const upcoming = () => {
    const d2 = resData
    const data2 = d2?.filter(s => { return (s.reservation_status.reservation_status !== ("Vacant") && s.reservation_status.reservation_status !== ("Occupied") && s.reservation_status.reservation_status !== ("Finished")) })
    setUpcomming(data2)
    console.log("upcoming data : ", data2)
    setReservationStatus(true)
    setAllReservation(false)
    setSeatedShow(false)
  }
  const allData = () => {
    const d3 = resData
    const data3 = d3?.filter(s => s.reservation_status.reservation_status !== "Vacant")
    setStatusAll(data3)
    setReservationStatus(false)
    setSeatedShow(false)
    setAllReservation(true)
  }
  useEffect(() => {
    seated();
    allData();
    upcoming();
  }, [])

  const statusChange = (reservation_id, status_id) => {
    const data = {
      "reservation_id": reservation_id,
      "status_id": status_id
    }
    restaurantSessionStore.changeShift(data)
      .then(([success, data]) => {
        if (success) {
          onHide();
          Walkins();
          Reservations();
          seated();
          allData();
          upcoming();
          toast.success(data);
          console.log("status change success : ", data)
        } else {
          toast.error(data)
          console.log("status change error : ", data)
        }
      })

  }
  const walkinstatusChange = (reservation_id, status_id) => {
    const data = {
      "reservation_id": reservation_id,
      "status_id": status_id
    }
    console.log("walkin delete clicked")
    restaurantSessionStore.changeShift(data)
      .then(([success, data]) => {
        if (success) {
          onHide();
          Walkins();
          console.log("status change success : ", data)
          toast.success(data)
        } else {
          toast.error(data)
          console.log("status change error : ", data)
        }
      })
  }

  const [extendingTime, setExtendingTime] = useState();

  const [upcommingSearch, setSearchUpcomming] = useState("");
  const searchUpcomming = () => {
    let data = upcomming.filter((name) => {
      console.log("name", name)
      if (upcommingSearch !== "") {
        // let f = (name.first_name).toLowerCase()
        // let l = (name.last_name).toLowerCase()
        let fullN = (name.guest.guest_name).toLowerCase()
        let check = upcommingSearch.toLowerCase()
        let res = fullN.includes(check) ? name.guest.guest_mobile : "No match Found";
        return res === name.guest.guest_mobile
      }
      else {
        console.log(upcomming)
        return upcomming
      }

    })
    setUpcomming(data);
  }


  const [seatedSearch, setSearchSeated] = useState("");
  const searchSeated = () => {
    let data = seatedStatus.filter((name) => {
      console.log("name", name)
      if (seatedSearch !== "") {
        // let f = (name.first_name).toLowerCase()
        // let l = (name.last_name).toLowerCase()
        let fullN = (name.guest.guest_name).toLowerCase()
        let check = seatedSearch.toLowerCase()
        let res = fullN.includes(check) ? name.guest.guest_mobile : "No match Found";
        return res === name.guest.guest_mobile
      }
      else {
        console.log(seatedStatus)
        return seatedStatus
      }

    })
    setSeatedStatus(data);
  }

  const [allSearch, setSearchAll] = useState("");
  const searchAll = () => {
    let data = statusAll.filter((name) => {
      console.log("name", name)
      if (allSearch !== "") {
        // let f = (name.first_name).toLowerCase()
        // let l = (name.last_name).toLowerCase()
        let fullN = (name.guest.guest_name).toLowerCase()
        let check = allSearch.toLowerCase()
        let res = fullN.includes(check) ? name.guest.guest_mobile : "No match Found";
        return res === name.guest.guest_mobile
      }
      else {
        console.log(statusAll)
        return statusAll
      }

    })
    setStatusAll(data);
  }


  const [walkinsSearch, setSearchWalkins] = useState("");
  const searchWalkins = () => {
    let data = walkinData.filter((name) => {
      console.log("name", name)
      if (walkinsSearch !== "") {
        // let f = (name.first_name).toLowerCase()
        // let l = (name.last_name).toLowerCase()
        let fullN = (name.guest.guest_name).toLowerCase()
        let check = walkinsSearch.toLowerCase()
        let res = fullN.includes(check) ? name.guest.guest_mobile : "No match Found";
        return res === name.guest.guest_mobile
      }
      else {
        console.log(walkinData)
        return walkinData
      }

    })
    setWalkinData(data);
  }


  const updateT = (reservation_id, time, e) => {
    e.preventDefault();
    let data = {
      "id": reservation_id,
      "time": time
    }
    if (time > 0) {
      restaurantSessionStore.updateTime(data)
        .then(([success, data]) => {
          if (success) {
            Reservations();
            seated();
            toast.success("Updated Successfully")
            setTimeout(() => {
              let seat = document?.getElementById('seated')
              seat?.click();
            }, 500);
            // window.history.go(0);
            onHide();
          } else {
            console.log("Updating time error data : ", data)
            toast.error(data)
          }
        })
      console.log("Updating time data : ", data)
    }
    // else onHide();

  }
  const updateWalkin = (reservation_id, time, e) => {
    e.preventDefault();

    let data = {
      "id": reservation_id,
      "time": time
    }
    if (time > 0) {
      restaurantSessionStore.updateTime(data)
        .then(([success, data]) => {
          if (success) {
            toast.success("Updated Successfully")
            setTimeout(() => {
              let seat = document?.getElementById('Showwalkin')
              seat?.click();
            }, 500);
            // window.history.go(0);
            onHide();
          } else {
            console.log("Updating time error data : ", data)
            toast.error(data)
          }
        })
      console.log("Updating time data : ", data)
    }
    // else onHide();

  }
  const onOccupiedClick = () => {
    setSResDetails(false);
    setSResStatus(true);
    setSQReminder(false);
    setQResStatus(false)
  }

  const onAssign = () => {
    setSResDetails(false);
    setSResStatus(false);
    setSQReminder(false);
    setQResStatus(true)

  }

  const onStatusClick = () => {
    setUResStatus(true)
    setUResDetails(false)
  }


  const onReservationClick = () => {
    setWalkinsList(false)
    setSubBtns(true)
    setReservationStatus(true)
    upcoming();
    Reservations();
  }

  const onWalkinClick = () => {
    setWalkinsList(true)
    setSubBtns(false)
    setReservationStatus(false)
    setAllReservation(false)
    setSeatedShow(false)
  }



  //Starting----adjusting re-rendering element position to start from top-left corner with padding of 40px

  var leftLow = uItems?.map(table => (
    parseInt(table.floor_plan_data.posx)
  ))
  var topLow = uItems?.map(table => (
    parseInt(table.floor_plan_data.posy)
  ))
  const leftPo = Math.min.apply(Math, leftLow) - 40
  const topPo = Math.min.apply(Math, topLow) - 40

  //Ending----

  return (
    <div className="floor-container">
      <Loader setLoader={setLoader} visible={loader} />
      <div className="table" style={{ left: show ? "-270px" : "110px" }} >
        <div className="reserve">
          <button className="main-btn" id="reservations" onClick={() => { onReservationClick(); Reservations(); }}>Reservation</button>
          <button className="main-btn" id="Showwalkin" onClick={() => { onWalkinClick(); Walkins(); }}>Walk-in</button>
          <span onClick={() => setShow(!show)} className="closeOp">
            {/* <i className={show ?"fas fa-chevron-left right":"fas fa-chevron-left "}></i> */}
            <img src={Left} className={show ? "right" : ""} alt="left" />
          </span>

        </div>

        {showSubBtn ?

          <div className="seats">
            <button className="sub-btn" id="seated" onFocus={Reservations} onClick={seated}>Seated</button>
            <button className="sub-btn" id="upcoming" autoFocus onFocus={Reservations} onClick={upcoming}>Upcoming</button>
            <button className="sub-btn" onFocus={Reservations} onClick={allData}>All</button>
          </div>
          : null}
        {/* <div className="search-inp">
                <i className="fa fa-search"></i>
                <input type="search" />
              </div> */}



        {seatedShow ?
          <div>
            <div className="search-inp">
              <i className="fa fa-search" onClick={searchSeated}></i>
              <input type="search" onChange={(e) => { setSearchSeated(e.target.value); seated(); }} />
            </div>
            <ul className="table-list">
              {seatedStatus?.map((item, i) => (
                <li onClick={() => onseatedClick(item)} key={i}>
                  <div className={item.floor_plan_is_online ? "t-no2" : "t-no"}>{item?.floor_plan_alias === "null" ? item?.table_type.table_name : item?.floor_plan_alias}</div>
                  <div className="c-details">
                    <p>{item?.guest?.guest_name}</p>
                    <p style={{ marginTop: "5px", textTransform: "uppercase" }}>{item?.floor_name}, {moment(item?.reserved_from, 'HH:mm:ss').local().format("hh:mm a")} to {moment(item?.reserved_to, 'HH:mm:ss').local().format('hh:mm a')}</p>

                  </div>
                  <div className="status">
                    <p
                      style={{
                        color:
                          item?.reservation_status.reservation_status_id === 3
                            ? "#0B8900"
                            : item?.reservation_status.reservation_status_id === 6
                              ? "#FF4500"
                              : item?.reservation_status.reservation_status_id === 5
                                ? "#FF9326"
                                : item?.reservation_status.reservation_status_id === 1
                                  ? "#510AEA"
                                  : "#747474",
                      }}
                    >

                      {item?.reservation_status.reservation_status_id === 1 ? "Waiting" :
                        item?.reservation_status.reservation_status_id === 2 ? "Cancelled" :
                          item?.reservation_status.reservation_status_id === 3 ? "Confirmed" :
                            item?.reservation_status.reservation_status_id === 4 ? "Finished" :
                              item?.reservation_status.reservation_status_id === 5 ? "Occupied" :
                                item?.reservation_status.reservation_status_id === 6 ? "No-Show" :
                                  item?.reservation_status.reservation_status_id === 7 ? "Vacant"
                                    : null}
                    </p>
                    <p>Status</p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          : null}

        {reservationStatus ?
          <div >
            <div className="search-inp">
              <i className="fa fa-search" onClick={searchUpcomming} ></i>
              <input type="search" onChange={(e) => { setSearchUpcomming(e.target.value); upcoming(); }} />
            </div>
            <ul className="table-list">
              {upcomming?.filter(s => s.reservation_status.reservation_status !== ("Finished")).map((item, i) => (
                <li onClick={() => onupcomingclick(item)} key={i}>
                  <div className={item.floor_plan_is_online ? "t-no2" : "t-no"}>{item.floor_plan_alias === "null" ? item.table_type.table_name : item.floor_plan_alias}</div>
                  <div className="c-details">
                    <p>{item?.guest?.guest_name}</p>
                    <p style={{ marginTop: "5px", textTransform: "uppercase" }}>{item?.floor_name}, {moment(item?.reserved_from, 'HH:mm:ss').local().format("hh:mm a")} to {moment(item?.reserved_to, 'HH:mm:ss').local().format('hh:mm a')}</p>

                  </div>
                  <div className="status">
                    <p
                      style={{
                        color:
                          item?.reservation_status.reservation_status_id === 1 ? "#E8AE03" :
                            item?.reservation_status.reservation_status_id === 2 ? "#FF4500" :
                              item?.reservation_status.reservation_status_id === 3 ? "#2EC114" :
                                item?.reservation_status.reservation_status_id === 4 ? "#000000" :
                                  item?.reservation_status.reservation_status_id === 5 ? "##FF9326" :
                                    item?.reservation_status.reservation_status_id === 6 ? "#676767" :
                                      item?.reservation_status.reservation_status_id === 7 ? "#2EC114"
                                        : "#747474",
                      }}
                    >
                      {item?.reservation_status.reservation_status_id === 1 ? "Waiting" :
                        item?.reservation_status.reservation_status_id === 2 ? "Cancelled" :
                          item?.reservation_status.reservation_status_id === 3 ? "Confirmed" :
                            item?.reservation_status.reservation_status_id === 4 ? "Finished" :
                              item?.reservation_status.reservation_status_id === 5 ? "Occupied" :
                                item?.reservation_status.reservation_status_id === 6 ? "No-Show" :
                                  item?.reservation_status.reservation_status_id === 7 ? "Vacant"
                                    : null}
                    </p>
                    <p>Status</p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          : null}

        {allReservation ?
          <div>
            <div className="search-inp">
              <i className="fa fa-search" onClick={searchAll}></i>
              <input type="search" onChange={(e) => { setSearchAll(e.target.value); allData(); }} />
            </div>
            <ul className="table-list">
              {statusAll?.map((item, i) => (
                <li key={i}>
                  <div className={item.floor_plan_is_online ? "t-no2" : "t-no"}>{item.floor_plan_alias === "null" ? item.table_type.table_name : item.floor_plan_alias}</div>
                  <div className="c-details">
                    <p>{item?.guest?.guest_name}</p>
                    <p style={{ marginTop: "5px", textTransform: "uppercase" }}>{item?.floor_name}, {moment(item?.reserved_from, 'HH:mm:ss').local().format("hh:mm a")} to {moment(item?.reserved_to, 'HH:mm:ss').local().format('hh:mm a')}</p>
                  </div>
                  <div className="status">
                    <p
                      style={{
                        color:
                          item?.reservation_status.reservation_status_id === 1 ? "#E8AE03" :
                            item?.reservation_status.reservation_status_id === 2 ? "#FF4500" :
                              item?.reservation_status.reservation_status_id === 3 ? "#2EC114" :
                                item?.reservation_status.reservation_status_id === 4 ? "#000000" :
                                  item?.reservation_status.reservation_status_id === 5 ? "#FF9326" :
                                    item?.reservation_status.reservation_status_id === 6 ? "#676767" :
                                      item?.reservation_status.reservation_status_id === 7 ? "#2EC114"
                                        : "#747474",
                      }}
                    >
                      {item?.reservation_status.reservation_status_id === 1 ? "Waiting" :
                        item?.reservation_status.reservation_status_id === 2 ? "Cancelled" :
                          item?.reservation_status.reservation_status_id === 3 ? "Confirmed" :
                            item?.reservation_status.reservation_status_id === 4 ? "Finished" :
                              item?.reservation_status.reservation_status_id === 5 ? "Occupied" :
                                item?.reservation_status.reservation_status_id === 6 ? "No-Show" :
                                  item?.reservation_status.reservation_status_id === 7 ? "Vacant"
                                    : null}
                    </p>
                    <p>Status</p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          : null}

        {showWalkins ?
          <div>
            <div className="search-inp">
              <i className="fa fa-search" onClick={searchWalkins}></i>
              <input type="search" onChange={(e) => { setSearchWalkins(e.target.value); Walkins(); }} />
            </div>
            <ul className="table-list2">
              <div className="walkin-add">
                <button style={{ display: "flex", padding: "7.2px 15px", width: "auto" }} className="sub-btn2 tbl-lst2" onClick={onWalkinDialog}><i style={{ fontSize: "20px" }} className="pi pi-plus-circle"> </i> &nbsp;&nbsp;Add Walkin</button>

              </div>
              {walkinData.map((item, i) => (
                <li key={i}>
                  {item?.reservation_status.reservation_status_id !== 1 ? item?.reservation_status.reservation_status_id !== 2 ?
                    <div className="t-no">{item.floor_plan_table_alias === "null" ? item.table_type.table_name : item.floor_plan_table_alias}</div>
                    : <div className="t-no">AUTO</div> : <div className="t-no">AUTO</div>}

                  <div onClick={() => { return item?.reservation_status.reservation_status_id !== 2 ? walkinStatus(item) : null; }} className="c-details">
                    <p>{item.guest.guest_name}</p>
                    {item?.reservation_status.reservation_status_id !== 1 ? item?.reservation_status.reservation_status_id !== 2 ?
                      <p style={{ marginTop: "2px", textTransform: "uppercase" }}>{item?.floor_name}, {moment(item?.reserved_from, 'HH:mm:ss').local().format("hh:mm a")} to {moment(item?.reserved_to, 'HH:mm:ss').local().format('hh:mm a')}</p>
                      : null : null}
                  </div>
                  <div className="status">
                    <div onClick={() => { return item?.reservation_status.reservation_status_id !== 2 ? walkinStatus(item) : null; }}>
                      <p
                        style={{
                          color:
                            item?.reservation_status.reservation_status_id === 1 ? "#E8AE03" :
                              item?.reservation_status.reservation_status_id === 2 ? "#FF4500" :
                                item?.reservation_status.reservation_status_id === 3 ? "#2EC114" :
                                  item?.reservation_status.reservation_status_id === 4 ? "#000000" :
                                    item?.reservation_status.reservation_status_id === 5 ? "#FF9326" :
                                      item?.reservation_status.reservation_status_id === 6 ? "#676767" :
                                        item?.reservation_status.reservation_status_id === 7 ? "#2EC114"
                                          : "#747474",
                        }}
                      >
                        {item?.reservation_status.reservation_status_id === 1 ? "Waiting" :
                          item?.reservation_status.reservation_status_id === 2 ? "Cancelled" :
                            item?.reservation_status.reservation_status_id === 3 ? "Confirmed" :
                              item?.reservation_status.reservation_status_id === 4 ? "Finished" :
                                item?.reservation_status.reservation_status_id === 5 ? "Occupied" :
                                  item?.reservation_status.reservation_status_id === 6 ? "No-Show" :
                                    item?.reservation_status.reservation_status_id === 7 ? "Vacant"
                                      : null}
                      </p>
                      <p>Status</p>
                    </div>

                    <div style={{ zIndex: "10", display: "grid", alignItems: 'center', marginRight: "10px" }}>
                      {item?.reservation_status.reservation_status_id !== 1 ?
                        item?.reservation_status.reservation_status_id !== 2 ?
                          <i onClick={() => { deleteWalkin(item.id) }} className="pi pi-trash red"></i>
                          : <i onClick={() => { deleteWalkin(item.id) }} className="pi pi-trash red"></i>
                        : <i onClick={() => walkinstatusChange(item?.id, 2)} style={{ color: "red", fontSize: "13px", fontWeight: "500", fontStyle: "normal" }}>Cancel</i>}
                    </div>

                  </div>
                </li>
              ))}
            </ul>
          </div>
          : null}



        <div className="fixed-footer">
          <img src={Footer} alt="" />
        </div>
      </div>
      <div className="images" >
        <div className="image-header">
          <div className='drpdn'>
            {/* <button>Shift</button> */}
            <button id='getShifts' onClick={shiftList} style={{ display: 'none' }}></button>
            <Dropdown id="shift" onFocus={floorPlanData} style={{ width: '150px', marginRight: '15px', textAlign: 'center' }} value={selectedShift} options={shifts} onChange={onShiftchange} optionLabel="name" placeholder="Select Shifts" />

            {/* <button>Ground Floor</button> */}
            <Dropdown id="floor" onFocus={floorPlanData} style={{ width: '150px', marginRight: '30px', textAlign: 'center' }} value={selectedFloor} options={floors} onChange={onFloorChange} optionLabel="name" placeholder="Select Floor" />

          </div>
        </div>
        <div style={{ right: show ? "0px" : "-379px" }} className="img-content">
          {uItems?.map((table, i) => (
            // <div style={{ position: "absolute", top: (table.floor_plan_data.posy - topPo) + 'px', left: (table.floor_plan_data.posx - leftPo) + 'px' }}>
            <div key={i} style={{ position: "absolute", top: (table.floor_plan_data.top_per) + '%', left: (table.floor_plan_data.left_per) + '%', transform: `rotate(${table.floor_plan_data.rotate_angle}deg)` }}>
              <div style={{ position: "relative" }}>
                {
                  <img height="70px" src={`./tables/${table.table_data.image_url}`} alt={`${table.table_data.image_url}`} />
                }
              </div>
              <div
                key={table.table_data.id}
                className={table.table_data.table_shape}
                status={table.status.desc}

              >
                <p className="tableName" style={{ transform: `rotate(${table.floor_plan_data.rotate_angle !== 0 ? -90 : 0}deg)` }}>{table.floor_plan_data.alias === (null || "null") ? table.table_data.table_type_name : table.floor_plan_data.alias}</p>
                {/* <p style={{transform: `rotate(${table.floor_plan_data.rotate_angle !==0?-90:0}deg)` }} className={table.shiftV === "vacant" ? "greenB tablestatus" : table.shiftV === "occupied" ? "redB tablestatus" : table.shiftV === "waiting" ? "orangeB tablestatus" : null}
                >.</p> */}
                <p style={{ transform: `rotate(${table.floor_plan_data.rotate_angle !== 0 ? -90 : 0}deg)` }} className={table.status.desc === "Vacant" ? "green tablevacancy" : table.status.desc === "Occupied" ? "red tablevacancy" : table.status.desc === "Waiting" ? "orange tablevacancy" : null}
                >{table.status.desc === "Vacant" ? "VA" : table.status.desc === "Occupied" ? "OC" : table.status.desc === "Waiting" ? "WL" : null}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <Dialog visible={dialog} position="center" height="auto" onHide={() => onHide()}>

        {showUResDetails ?

          <div className='resDetails'>
            <div className='detailsHolder'>
              <div className='detailsCard'>
                <h5>RESERVATION DETAILS</h5>
                <span onClick={() => onHide()}><i className="pi pi-times close"></i></span>
                <hr />
                <div className="name">
                  <h3>
                    {userdtls?.guest?.guest_name}
                  </h3>
                  <p >{userdtls?.floor_plan_alias === "null" ? userdtls?.table_type.table_name : userdtls?.floor_plan_alias}, {moment(userdtls?.reserved_from, 'HH:mm:ss').format('hh:mm a')} to {moment(userdtls?.reserved_to, 'HH:mm:ss').format('hh:mm a')}, {userdtls?.booked_date}.
                  </p>
                </div>
                <div className="description">
                  <h5 >Reservation Notes</h5>
                  <p>{userdtls?.note === "" || userdtls?.note === null ? "Empty" : userdtls?.note}</p>
                </div>
                <div className="status">
                  <h5 >Status</h5>
                  <button className='' onClick={onStatusClick} style={{ fontSize: '14px', textAlign: 'initial', color: '#FDFEFF' }}>
                    {userdtls?.reservation_status.reservation_status_id === 1 ? "Waiting" :
                      userdtls?.reservation_status.reservation_status_id === 2 ? "Cancelled" :
                        userdtls?.reservation_status.reservation_status_id === 3 ? "Confirmed" :
                          userdtls?.reservation_status.reservation_status_id === 4 ? "Finished" :
                            userdtls?.reservation_status.reservation_status_id === 5 ? "Occupied" :
                              userdtls?.reservation_status.reservation_status_id === 6 ? "No-Show" :
                                userdtls?.reservation_status.reservation_status_id === 7 ? "Vacant" : null
                    } <i className="pi pi-play" style={{ color: 'white', marginLeft: '175px', marginTop: '5px', fontSize: '9px' }}></i></button>
                </div>
                {/* <h3 style={{ marginTop: "5px" }}>
                  Tags
                <span style={{ float: "right" }}>ADD MORE</span>
                </h3>
               
                <div className="search-inp">
                  
                  <Chips value={chips} onChange={(e) => setChips(e.value)} />
                </div> */}

                <div className="icons">
                  <a href={`tel:${userdtls?.guest_id?.mobile}`}><i className="pi pi-phone icon-a"></i></a>
                  {/* <i className="pi pi-comment icon-a"></i> */}
                  {/* <i style={{ border: "none" }} className="pi pi-trash redB"></i> */}
                </div>
                <div className="cr">
                  <p style={{ marginTop: "15px" }} onClick={() => statusChange(userdtls?.id, 2)} >Cancel Reservation</p>
                  {/* <p >Reset</p> */}
                </div>
              </div>
            </div>
          </div>
          : null}

        {showUResStatus ?

          <div className='resStatus'>
            <div className='detailsHolder'>
              <div className='detailsCard'>
                <h5>RESERVATION STATUS</h5>
                <span onClick={() => onHide()}><i className="pi pi-times close"></i></span>
                <hr />
                <h3 style={{ fontSize: '14px', color: '#393939', fontWeight: '700' }}>
                  {userdtls?.guest?.guest_name}</h3>
                <p style={{ textAlign: 'center', marginTop: '5px', fontSize: '12px', fontWeight: '400', color: '#747474' }}>{userdtls.floor_plan_alias === "null" ? userdtls.table_type.table_name : userdtls.floor_plan_alias}, {moment(userdtls?.reserved_from, 'HH:mm:ss').local().format('hh:mm a')} to {moment(userdtls?.reserved_to, 'HH:mm:ss').local().format('hh:mm a')}, {userdtls?.booked_date}.
                </p>

                <div className='statusBtn'>
                  {/* <button className=' status' onClick={() => statusChange(userdtls?.id, 1)}  >Waiting</button> */}
                  {/* <button className='status' onClick={() => statusChange(userdtls?.id, 2)}>Cancelled</button> */}
                  <button className='status' onClick={() => statusChange(userdtls?.id, 3)}>Confirmed</button>
                  {/* <button className='status' onClick={() => statusChange(userdtls?.id, 4)}>Finished</button> */}
                  <button className='status' onClick={() => statusChange(userdtls?.id, 5)}>Occupied</button>
                  <button className='status' onClick={() => statusChange(userdtls?.id, 6)}>No-Show</button>
                  {/* <button className='status' onClick={() => statusChange(userdtls?.id, 7)}>Vacant</button> */}
                  {/* <hr /> */}
                </div>
              </div>
            </div>
          </div>
          : null}
      </Dialog>

      <Dialog visible={dialog1} position="center" onHide={() => onHide()}>

        <Formik


          initialValues={{ fname: '', lname: '', email: '', phone: '', otp: '' }}
          validationSchema={yup.object({
            fname: yup.string()
              .required('Required'),
            lname: yup.string()
              .required('Required'),

            // email: yup.string().email('Must be a valid email').max(255).required('Email is required'),


          })}
          onSubmit={(values) => { addWalkin(values); }}>
          {({ errors, status, touched }) => (
            <Form autoComplete="off">
              <div className="walkingQueue">
                <div className="queueForm">
                  <div className='queueOne'>
                    <h5 style={{ marginBottom: '10px', color: '#510AEA' }}>WALK-IN QUEUE <span onClick={() => { onHide(); setMobile(""); setGuestCount(1); setOtp2(false); setBlockAdd(true) }}><i className="pi pi-times close"></i></span>
                    </h5>
                    <hr />
                    <p style={{ margin: "15px 0", fontSize: "12px", color: "red" }}>{walkinmsg ? "--" + walkinmsg + "--" : null}</p>
                    <label  >First Name<ErrorMessage name='fname' component={FormErrorMsg} /></label>
                    <div className="p-inputgroup hndl-mr">
                      <Field required style={{ marginTop: '5px', marginBottom: '15px', width: "300px" }} as={InputText} name="fname" className={(errors.fname && touched.fname ? ' is-invalid' : 'p-inputgroup hndl-mr')} />
                    </div>
                    <label >Last Name<ErrorMessage name='lname' component={FormErrorMsg} /></label>
                    <div className="p-inputgroup hndl-mr">
                      <Field required style={{ marginTop: '5px', marginBottom: '15px' }} as={InputText} name="lname" className={(errors.lname && touched.lname ? ' is-invalid' : 'p-inputgroup hndl-mr')} />
                    </div>
                    {/* <label >Email<ErrorMessage name='email' component={FormErrorMsg} /></label>
                    <div className="p-inputgroup hndl-mr">
                      <Field required style={{ marginTop: '5px', marginBottom: '15px' }} as={InputText} name="email" className={(errors.email && touched.email ? ' is-invalid' : 'p-inputgroup hndl-mr')} />
                    </div> */}
                    <label style={{ marginTop: '15px' }} >Number of Guests<ErrorMessage name='name' component={FormErrorMsg} /></label>
                    <div className="p-inputgroup hndl-mr">
                      <InputNumber min={1} max={custData} style={{ marginTop: '10px', marginBottom: '15px' }} id="horizontal" name="above" className="numinc" value={guestCount} onChange={e => Guest(e)} showButtons buttonLayout="horizontal"
                        decrementButtonClassName="p-inpnumber-button" incrementButtonClassName="p-inpnumber-button" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" />
                      {/* <Field  style={{marginTop:'5px',marginBottom:'10px'}} as={InputText} name="name"  className={ (errors.name && touched.name ? ' is-invalid' : 'p-inputgroup hndl-mr') } /> */}
                    </div>
                    <label style={{ marginTop: '15px' }}>Phone Number<span id="phone"
                      style={{ display: "none", float: "right", fontSize: "14px" }}
                      className="float-right">Required</span></label>

                    <div id="pinp" className="p-inputgroup gotp hndl-mr code">

                      <Field as={PhoneInput} required type="tel" onlyCountries={['gb', 'in']}
                        country={'gb'} name="phone" className={(errors.phone && touched.phone ? ' is-invalid' : 'input getotp')} value={mobile}
                        onChange={(e) => {
                          if (e?.length <= 11) {
                            let p = document.getElementById('phone')
                            let pi = document.getElementById('pinp')
                            p.style.color = "red"
                            p.style.display = "initial"
                            pi.style.border = "1px solid red"
                          }
                          else {
                            let p = document.getElementById('phone')
                            let pi = document.getElementById('pinp')
                            p.style.display = "none"
                            pi.style.border = "none"

                          }
                          setMobile('+' + e)
                          // if (mobile.length === 13) {
                          //   setHideotp(true)
                          // }
                          // else setHideotp(false)
                        }}
                        onBlur={(e) => {
                          console.log("on blur phone", e.target.value)
                          if (e.target.value?.length <= 3) {
                            let p = document.getElementById('phone')
                            let pi = document.getElementById('pinp')
                            p.style.color = "red"
                            p.style.display = "initial"
                            pi.style.border = "1px solid red"
                          }
                          else {
                            let p = document.getElementById('phone')
                            let pi = document.getElementById('pinp')
                            p.style.display = "none"
                            pi.style.border = "none"

                          }
                        }}
                      />
                      {/* {hideotp?
                      <span onClick={getOtp} className='p-inputgroup-addon'>
                        Get OTP
                      </span>:null} */}

                    </div>
                    {/* {hideotp?<>
                    <label style={{ marginTop: '15px' }}>OTP</label>

                    <div className="p-inputgroup hndl-mr">

                      <Field
                        as={OtpInput}
                        value={otp2}
                        onChange={e => verifyOtp(e)}
                        numInputs={6}
                        maxLimit={6}
                        className={(errors.otp && touched.otp ? ' is-invalidinp' : 'otpField')}
                        separator={<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>}
                      />
                      {/* <Field style={{marginTop:'5px',marginBottom:'15px'}} as={InputMask} name="otp" className={ (errors.otp && touched.otp ? ' is-invalid' : 'input') } mask="9 9 9 9 9 9" value={otp} onChange={(e) => { setOtp(e.target.value); }}  /> 
                    </div>
                    </>:null} */}
                    <br />
                    <br />
                    <button
                      //  disabled={blockAdd}
                      className='walkin-add-btn'>ADD</button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Dialog>

      <Dialog visible={dialog2} position="center" onHide={() => onHide()}>

        {showSResDetails ?
          <div className="popUp3">
            <div className="popUp3-card">
              <form onSubmit={(e) => updateT(userdtls2?.id, extendingTime, e)}>
                <h5>RESERVATION DETAILS</h5>
                <i onClick={() => onHide()} className="pi pi-times close"></i>
                <hr />

                <div className="childOne">
                  <h3>
                    {userdtls2?.guest?.guest_name}

                  </h3>
                  <p>{userdtls2?.floor_plan_alias === "null" ? userdtls2?.table_type.table_name : userdtls2?.floor_plan_alias}, {moment(userdtls2?.reserved_from, 'HH:mm:ss').local().format('hh:mm a')} to {moment(userdtls2?.reserved_to, 'HH:mm:ss').local().format('hh:mm a')}, {userdtls2?.booked_date}.
                  </p>
                </div>

                <div className="childTwo">
                  <p>Status</p>
                  <button type="button" onClick={onOccupiedClick} className='status-btn' >{userdtls2?.reservation_status.reservation_status}<i className="pi pi-play" style={{ color: 'white', marginLeft: '210px', marginTop: '0px', fontSize: '9px' }}></i></button>
                  <p>Extend Time Slot [min]</p>
                </div>
                <div className="occupancyTime">
                  <input min="0" max="60" required className="extendTime" onChange={(e) => setExtendingTime(e.target.value)} type="number" />

                </div>
                <hr />
                {/* <div className="done-btn"> */}
                <button type="submit"
                  // onClick={() => updateT(userdtls2?.id, extendingTime)}
                  className='primary'>DONE</button>
                {/* </div> */}

                <div className="cancel">
                  {/* <p style={{ marginTop: "15px" }} onClick={() => statusChange(userdtls2?.id, 2)}>Cancel Reservation</p> */}
                  {/* <p>Reset</p> */}
                </div>
              </form>
            </div>
          </div>
          : null}

        {
          showSResStatus ?
            <div className="popUp5">
              <div className="popUp5-card">
                <h5>RESERVATION STATUS</h5>
                <i onClick={() => onHide()} className="pi pi-times close"></i>
                <hr />
                <div className="child1">
                  <h3>
                    {userdtls2?.guest?.guest_name}
                  </h3>
                  <p>{userdtls2?.floor_plan_alias === "null" ? userdtls2?.table_type.table_name : userdtls2?.floor_plan_alias}, {moment(userdtls2?.reserved_from, 'HH:mm:ss').local().format('hh:mm a')} to {moment(userdtls2?.reserved_to, 'HH:mm:ss').local().format('hh:mm a')}, {userdtls2?.booked_date}.
                  </p>
                </div>
                <br />
                <div className="child2">
                  <button onClick={() => { statusChange(userdtls2?.id, 4); onHide(); }} className=' stat' style={{ fontWeight: '600' }} >Complete &amp; Set Table Vacant</button>
                </div>
              </div>
            </div>
            : null
        }

        {
          showSQReminder ?
            <div className='queReminder'>
              <div className='detailsHolder'>
                <div className='detailsCard'>
                  <h5>QUEUE REMINDER</h5>
                  <i onClick={() => onHide()} className="pi pi-times close"></i>
                  <hr />
                  <div className="content1">
                    <div className='childOne'>
                      <h3>
                        Looks like A-T1 suits reservation requirements
                        for the following customer.
                      </h3>
                      <p >Do you want to auto assign the table?
                      </p>
                    </div>
                    <div className='childTwo'>
                      <h3>
                        Dylan Rivers
                      </h3>
                      <p style={{ textAlign: 'center' }}>
                        T3,9:00 A.M,SAT,21 DEC 2020.
                      </p>
                    </div>
                    <div className='suitableTbl'>
                      <div className='childThree'>
                        <p >Suitable Table</p>
                        <h5 >A-T1-VACANT</h5>
                      </div>
                      <div className='childFour'>
                        <p>Time reserved for [default]:</p>
                        <h2>45 Minutes</h2>
                      </div>
                      <button onClick={onAssign} className='assign-btn'>ASSIGN</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            : null
        }

        {
          showQResStatus ?
            <div className="popUp4">
              <div className="popUp4-card">
                <h5>RESERVATION STATUS</h5>
                <i onClick={() => onHide()} className="pi pi-times close"></i>
                <hr />
                <div className="child1">
                  <h3>
                    Dylan Rivers
                  </h3>
                  <p>T3,9:00 A.M,SAT,21 DEC 2020.
                  </p>
                </div>
                <div className='suitableTbl'>
                  <div className='childThree'>
                    <p>Customer is moved to seated</p>
                    <h5 >A-T1-OCCUPIED</h5>
                  </div>
                  <div className='childFour'>
                    <p>Time reserved for [default]:</p>
                    <h2>45 Minutes</h2>
                  </div>
                  <button className='assign-btn'>OK</button>
                </div>
              </div>
            </div>
            : null}
      </Dialog>

      <Dialog visible={dialog3} position="center" onHide={() => onHide()}>
        <div className="resCancelled">
          <div className="cancelDetails">
            <i className="pi pi-times cancel-close" style={{ marginLeft: '10px' }}></i>
            <h4 style={{ fontWeight: '600', textAlign: 'center' }}>Reservation Cancelled</h4>
            <p style={{ fontSize: '12px', textAlign: "center" }}>Undo</p>
          </div>
        </div>
      </Dialog>

      <Dialog visible={walkindialog} position="center" onHide={() => onHide()}>
        {walkinOptn ?
          <div className="popUp3">
            <div className="popUp3-card">
              <form onSubmit={(e) => updateWalkin(walkinuser?.id, extendingTime, e)}>
                <h5>WALKIN DETAILS</h5>
                <i onClick={() => { setWalkinOptn(true); onHide(); }} className="pi pi-times close"></i>
                <hr />

                <div className="childOne">
                  <h3>
                    {walkinuser?.guest?.guest_name}

                  </h3>
                  <p>{walkinuser?.floor_plan_table_alias === "null" ? walkinuser?.table_type.table_name : walkinuser?.floor_plan_table_alias}, {moment(walkinuser?.reserved_from, 'HH:mm:ss').local().format('hh:mm a')} to {moment(walkinuser?.reserved_to, 'HH:mm:ss').local().format('hh:mm a')}, {walkinuser?.booked_date}.
                  </p>
                </div>

                <div className="childTwo">
                  <p>Status</p>
                  <button type="button" onClick={() => setWalkinOptn(false)} className='status-btn' >{walkinuser?.reservation_status.reservation_status}<i className="pi pi-play" style={{ color: 'white', marginTop: '5px', fontSize: '9px', float: "right" }}></i></button>
                  <p>Extend Time Slot [min]</p>
                </div>
                <div className="occupancyTime">
                  <input min="0" max="60" required className="extendTime" onChange={(e) => setExtendingTime(e.target.value)} type="number" />

                </div>
                <hr />
                {/* <div className="done-btn"> */}
                <button type="submit"
                  // onClick={() => updateWalkin(walkinuser?.id, extendingTime)}
                  className='primary'>DONE</button>
                {/* </div> */}

                <div className="cancel">
                  {/* <p style={{ marginTop: "15px" }} onClick={() => statusChange(userdtls2?.id, 2)}>Cancel Reservation</p> */}
                  {/* <p>Reset</p> */}
                </div>
              </form>
            </div>
          </div>
          :

          <div className='resStatus'>
            <div className='detailsHolder'>
              <div className='detailsCard'>
                <div style={{ position: "fixed", top: 0, width: "90%", backgroundColor: "#fff", paddingTop: "10px" }}>
                  <h5>WALK-IN STATUS</h5>
                  <span onClick={() => { onHide(); setWalkinOptn(true); }}><i className="pi pi-times close"></i></span>
                  <hr />
                </div>
                <br />
                <h3 style={{ fontSize: '14px', color: '#393939', fontWeight: '700' }}>
                  {walkinuser?.guest?.guest_name}</h3>
                <p style={{ textAlign: 'center', marginTop: '5px', fontSize: '12px', fontWeight: '400', color: '#747474' }}>{walkinuser?.floor_plan_table_alias === "null" ? walkinuser?.table_type.table_name : walkinuser?.floor_plan_table_alias}, {moment(walkinuser?.reserved_from, 'HH:mm:ss').local().format('hh:mm a')} to {moment(walkinuser?.reserved_to, 'HH:mm:ss').local().format('hh:mm a')} {walkinuser?.booked_date}.
                </p>
                <div className='statusBtn'>
                  {/* <button className=' status' onClick={() => walkinstatusChange(walkinuser?.id, 1)}  >Waiting</button> */}
                  <button className='status' onClick={() => walkinstatusChange(walkinuser?.id, 2)}>Cancelled</button>
                  <button className='status' onClick={() => walkinstatusChange(walkinuser?.id, 3)}>Confirmed</button>
                  <button className='status' onClick={() => walkinstatusChange(walkinuser?.id, 4)}>Finished</button>
                  <button className='status' onClick={() => walkinstatusChange(walkinuser?.id, 5)}>Occupied</button>
                  <button className='status' onClick={() => walkinstatusChange(walkinuser?.id, 6)}>No-Show</button>
                  {/* <button className='status' onClick={() => walkinstatusChange(walkinuser?.id, 7)}>Vacant</button> */}
                  {/* <hr /> */}
                </div>
              </div>
            </div>
          </div>
        }
      </Dialog>
    </div>

  );
};

export default Floor;

export const reservations = async () => {
  console.log("current url : ", window.location.pathname)
  if (window.location.pathname === '/') {
    setTimeout(() => {
      let clk3 = document?.getElementById('getShifts')
      clk3?.click();

      let clk2 = document?.getElementById('shift')
      clk2?.click();
    }, 99);
    let clk = document?.getElementById('reservations')
    setTimeout(() => {
      clk?.click();
    }, 100);

    console.log("clicked", clk)
  }
}