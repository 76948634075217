import React, { Fragment } from 'react';
import "./seatedReservationStatus.css";

const PopUp5 = () => {
    return <Fragment>
        <div className="popUp5">
            <div className="popUp5-card">
            <h5>RESERVATION STATUS</h5>
                    <i className="pi pi-times close"></i>
                    <hr />


                    <div className="child1">
                    <h3>
                        Isha Mosley
                   </h3>
                    <p>T3,9:00 A.M,SAT,21 DEC 2020.
                   </p>  

                    </div>

                    <div className="child2">
                    <button  className=' stat' style={{fontWeight:'600'}} >Complete & Set Table Vacant</button>
                    <button className='stat'>About to Vacant [10min - Default]</button>
 
                    </div>
            </div>
        </div>
    </Fragment>}



    export default PopUp5;