import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useStore } from './stores/useStore';
import { observer } from 'mobx-react-lite';



const AuthRoute:React.FC = observer((props: any) => {
    const { waiterSessionStore } = useStore();
    const [redirect, setRedirect] = useState(Boolean)
    // const [loader,setLoader]=useState(false);
    useEffect(() => {
        waiterSessionStore.isAuthenticated ? setRedirect(false) : setRedirect(true);
    }, [waiterSessionStore.isAuthenticated])
    console.log("redirect out", redirect)
    return (redirect ? <Redirect to={{ pathname: "/signin", state: { from: props.location } }} /> : props.children)
})
export default AuthRoute