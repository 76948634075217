import React, { Fragment } from 'react';
import "./queueReminder.css";


const QueueReminder = () => {
    return <Fragment>
        <div className='queReminder'>
            <div className='detailsHolder'>
                <div className='detailsCard'>
                    <h5>QUEUE REMINDER</h5>
                    <i className="pi pi-times close"></i>
                    <hr />
                    <div className="content1">
                        <div className='childOne'>
                            <h3>
                                Looks like A-T1 suits reservation requirements
                                for the following customer.
                   </h3>

                            <p >Do you want to auto assign the table?
                   </p>
                        </div>
                        <div className='childTwo'>

                            <h3>
                                Dylan Rivers
                   </h3>
                            <p style={{ textAlign: 'center' }}>
                                T3,9:00 A.M,SAT,21 DEC 2020.
                   </p>
                        </div>
                        <div className='suitableTbl'>
                            <div className='childThree'>
                                <p >Suitable Table</p>
                                <h5 >A-T1-VACANT</h5>
                            </div>

                            <div className='childFour'>
                                <p>Time reserved for [default]:</p>

                                <h2>45 Minutes</h2>
                            </div>

                            <button className='assign-btn'>ASSIGN</button>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </Fragment>
}

export default QueueReminder;