import { Fragment, useState } from "react";
import React from 'react';
import { InputMask } from 'primereact/inputmask';
import { Button } from 'primereact/button';
import styles from './newPin.module.css';
import { Link } from "react-router-dom";
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import styled from 'styled-components';
import { useStore } from "../stores/useStore";
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const FormErrorMsg = styled.span`
 color: red;
 float:right;
 font-size:12px;
`
toast.configure()

const NewPin = () => {

    const [mobile, setMobile] = useState();

    const { waiterSessionStore } = useStore();
    const history = useHistory();
    const pinReset = (mobile) => {
        const num = { "mobile": mobile }

        waiterSessionStore.waiterRestPin(num)
            .then(([success, data]) => {
                if (success) {
                    console.log("pin reset success data", data);
                    toast.success("New pin sent to your mail")

                    waiterSessionStore.logout()
                    history.push('/signin')

                } else {
                    toast.error(data)
                    console.log("pin reset error data", data);

                }
            })
    }
    return (
        <Fragment>
            <div className={styles.newPin}>
                <div className={styles.container}>
                    <Formik
                        initialValues={{ phone: "" }}
                        // validationSchema={yup.object({
                        //     phone: yup.string()
                        //         .required('Required')
                        // })}
                        onSubmit={(values) => { pinReset(values.phone); console.log(values); }}>
                        {({ errors, touched }) => (
                            <Form>
                                <img className={styles.loginlogo} src="logo/purple bg.svg" alt="logo" />
                                <div className={styles.inputHold}>
                                    <p>Enter your phone number <span id="phone"
                                        style={{ display: "none", float: "right", fontSize: "14px" }}
                                        className="float-right">Required</span></p>
                                    <div className="p-inputgroup">
                                        {/* <span className="p-inputgroup-addon">
                                            <i className="pi pi-mobile"></i>
                                        </span> */}
                                        <div id="pinp" className="code">

                                            <Field as={PhoneInput} required type="tel" onlyCountries={['gb', 'in']}
                                                country={'gb'} value={mobile}
                                                onChange={(e) => {
                                                    if (e?.length <= 11) {
                                                        let p = document.getElementById('phone')
                                                        let pi = document.getElementById('pinp')
                                                        p.style.color = "red"
                                                        p.style.display = "initial"
                                                        pi.style.border = "1px solid red"
                                                    }
                                                    else {
                                                        let p = document.getElementById('phone')
                                                        let pi = document.getElementById('pinp')
                                                        p.style.display = "none"
                                                        pi.style.border = "none"

                                                    }

                                                    setMobile('+' + e)
                                                }}
                                                onBlur={(e) => {
                                                    console.log("on blur phone", e.target.value)
                                                    if (e.target.value?.length <= 3) {
                                                        let p = document.getElementById('phone')
                                                        let pi = document.getElementById('pinp')
                                                        p.style.color = "red"
                                                        p.style.display = "initial"
                                                        pi.style.border = "1px solid red"
                                                    }
                                                    else {
                                                        let p = document.getElementById('phone')
                                                        let pi = document.getElementById('pinp')
                                                        p.style.display = "none"
                                                        pi.style.border = "none"

                                                    }
                                                }}
                                                name="phone" className={(errors.phone && touched.phone ? styles.isInvalid : styles.input)} />
                                        </div>
                                    </div>
                                    <Link to="/signin"><Button label="BACK TO LOGIN" className={styles.buttonLink} /></Link>
                                    <Button type="submit" label="SUBMIT" className={styles.buttonOutlined} />
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </Fragment>
    )
}
export default NewPin;