import React, { Fragment } from 'react';
import newPin from "./Pages/newPin";
import signin from "./Pages/signin";
import changePin from "./Pages/changePin";
import { Route, Switch } from 'react-router-dom';
import AuthRoute from './AuthRoute'
import App from "./App";

const AppWrapper = () => {
    return <Fragment >
       <Switch>
        <Route exact path="/newPin" component={newPin} />
        <Route exact path="/signin" component={signin} />
        <Route exact path="/changePin" component={changePin} />
        <AuthRoute>
            <App />
        </AuthRoute>
        </Switch>
    </Fragment>
}

export default AppWrapper