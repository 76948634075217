import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import "./guestBook.css";
import { useStore } from "../stores/useStore";



const GuestBook = () => {
    const [errorMsg, setErrorMsg] = useState(null)
    const [data, setData] = useState([])


    const { restaurantSessionStore } = useStore();

    useEffect(() => {
       
        restaurantSessionStore.getGuestList()
            .then(([success, data]) => {
                if (success) {
                    setData(data);
                    console.log("guestbook data",data);

                } else {
                    setErrorMsg(data)
                }
            })
           
    }, [])


    const deleteTemplate = (rowData) => {
        return <a href='#' style={{ color: 'red' }} >Delete</a>
    }

    const phoneTemplate = (rowData) => {
           return <a href={`tel:${rowData.telephone}`}>{rowData.telephone}</a>
       
    }
    const products = [
        { id: "1000", name: "John Doe", address:"76 Nottingham Rd ABERDULAIS SA10 6TA ,UK", country: "United Kingdom", email: "john.doegmail.com", mobile: "(404) 556 4444", telephone: 9822884898, tags: "VIP" },
        { id: "1001", name: "Peter Parker", address: "76 Nottingham Rd ABERDULAIS SA10 6TA ,UK", country: "United Kingdom", email: "john.doegmail.com", mobile: "(424) 536 4431", telephone: 9990193488, tags: "VIP" },
        { id: "1002", name: "Captain Steave", address: "76 Nottingham Rd ABERDULAIS SA10 6TA ,UK", country: "United Kingdom", email: "john.doegmail.com", mobile: "(443) 556 4124", telephone: 9864261720, tags: "VIP" },
        { id: "1003", name: "Hulk Smith", address: "76 Nottingham Rd ABERDULAIS SA10 6TA ,UK", country: "United Kingdom", email: "john.doegmail.com", mobile: "(434) 554 6785", telephone: 9027453289, tags: "VIP" },
        { id: "1004", name: "Vision Vector", address: "76 Nottingham Rd ABERDULAIS SA10 6TA ,UK", country: "United Kingdom", email: "john.doegmail.com", mobile: "(675) 845 2456", telephone: 9721923786, tags: "VIP" },
        { id: "1005", name: "Gamora Villiams", address: "76 Nottingham Rd ABERDULAIS SA10 6TA ,UK", country: "United Kingdom", email: "john.doegmail.com", mobile: "(563) 432 3456", telephone: 9098740921, tags: "VIP" },
        { id: "1006", name: "Robert SR", address: "76 Nottingham Rd ABERDULAIS SA10 6TA ,UK", country: "United Kingdom", email: "john.doegmail.com", mobile: "(543) 334 4563", telephone: 9876894412, tags: "VIP" },
        { id: "1007", name: "Neal Arm", address: "76 Nottingham Rd ABERDULAIS SA10 6TA ,UK", country: "United Kingdom", email: "john.doegmail.com", mobile: "(879) 132 6789", telephone: 9822874598, tags: "VIP" },
        { id: "1008", name: "Kristoffer", address: "76 Nottingham Rd ABERDULAIS SA10 6TA ,UK", country: "United Kingdom", email: "john.doegmail.com", mobile: "(231) 567 9372", telephone: 9984584898, tags: "VIP" },
        { id: "1009", name: "Twilight Danny", address: "76 Nottingham Rd ABERDULAIS SA10 6TA ,UK", country: "United Kingdom", email: "john.doegmail.com", mobile: "(583) 556 2352", telephone: 9822884097, tags: "VIP" },
        { id: "1010", name: "Mogambo ruslet", address: "76 Nottingham Rd ABERDULAIS SA10 6TA ,UK", country: "United Kingdom", email: "john.doegmail.com", mobile: "(453) 124 4542", telephone: 9067884898, tags: "VIP" },
        { id: "1020", name: "Kwinton kuaski", address: "76 Nottingham Rd ABERDULAIS SA10 6TA ,UK", country: "United Kingdom", email: "john.doegmail.com", mobile: "(576) 556 4325", telephone: 9867674898, tags: "VIP" },
        { id: "1030", name: "dwella tina", address: "76 Nottingham Rd ABERDULAIS SA10 6TA ,UK", country: "United Kingdom", email: "john.doegmail.com", mobile: "(346) 353 7684", telephone: 9826754398, tags: "VIP" },
        { id: "1040", name: "richard poly", address: "76 Nottingham Rd ABERDULAIS SA10 6TA ,UK", country: "United Kingdom", email: "john.doegmail.com", mobile: "(342) 252 7087", telephone: 9996644898, tags: "VIP" },

    ];
    return (
        <div style={{ margin: '20px 40px' }}>
            <div className="guestBook" >
                <h2 style={{fontWeight:'500',color:"#510AEA"}}>Guest List</h2>
                <hr className="hr" />
                <br/>
                <div className="card">
                    <DataTable value={data} paginator sortMode="multiple" rows={10} rowsPerPageOptions={[10, 20, 50]}>
                        <Column field="name" header="Name" sortable  ></Column>
                        <Column field="mobile"  header="Mobile" sortable ></Column>
                        {/* <Column field="notes" header="Notes" sortable  ></Column> */}

                    </DataTable>
                </div>
            </div>
        </div>
    );
}

export default GuestBook