import React, { Fragment } from 'react';
import "./upcomingReservationStatus.css";

const PopUp4 = () => {
    return <Fragment>

    <div className="popUp4">
        <div className="popUp4-card">
        <h5>RESERVATION STATUS</h5>
                    <i className="pi pi-times close"></i>
                    <hr />

                    <div className="child1">
                    <h3>
                        Dylan Rivers
                   </h3>
                    <p>T3,9:00 A.M,SAT,21 DEC 2020.
                   </p>  

                    </div>

                    <div className='suitableTbl'>
                            <div className='childThree'>
                                <p>Customer is moved to seated</p>
                                <h5 >A-T1-OCCUPIED</h5>
                            </div>

                            <div className='childFour'>
                                <p>Time reserved for [default]:</p>

                                <h2>45 Minutes</h2>
                            </div>

                            <button className='assign-btn'>OK</button>
                        </div>

        </div>

    </div>
    </Fragment>}




    export default PopUp4;