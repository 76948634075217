import { Fragment, useState } from "react";
import React from 'react';
import { InputMask } from 'primereact/inputmask';
import { Button } from 'primereact/button';
import styles from './newPin.module.css';
import { Link } from "react-router-dom";
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import styled from 'styled-components';
import { useStore } from "../stores/useStore";
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const FormErrorMsg = styled.span`
 color: red;
 float:right;
 font-size:12px;
`
toast.configure()

const NewPin: React.FC = () => {
    const { waiterSessionStore } = useStore();
    const history = useHistory();

    const changePin = (values:any) => {
        const data = {
            "current_password": values.oldpin,
            "new_password": values.newpin
          }
        waiterSessionStore.waiterChangePin(data)
            .then(([success, data]) => {
                if (success) {
                    console.log("changepin  success data", data);
                    toast.success("Pin changed successfully")
                    waiterSessionStore.logout()
                    history.push('/signin')
                } else {
                    toast.error(data)
                    console.log("changepin  error data", data);

                }
            })
            console.log(values);
    }

    return (
        <Fragment>
            <div className={styles.newPin}>
                <div className={styles.container}>
                    <Formik
                        initialValues={{ oldpin: "", newpin: "", cnfpin: "" }}
                        validationSchema={yup.object({
                            oldpin: yup.string()
                                .required('Required'),
                            newpin: yup.string()
                                .test('passwords-match', 'cannot use old password', function (value) {
                                    return this.parent.oldpin !== value
                                })
                                .required('Required'),
                            cnfpin: yup.string()
                                .oneOf([yup.ref('newpin'), null], 'Not a match')
                                .required('Required'),
                        })}
                        onSubmit={(values) => {changePin(values);}}>
                        {({ errors, touched }) => (
                            <Form className={styles.cp}>
                                {/* <h1>LOGO.</h1> */}
                                <p>Current Pin <ErrorMessage name='oldpin' component={FormErrorMsg} /></p>
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-lock"></i>
                                    </span>
                                    <Field as={InputMask} autoComplete="off" name="oldpin" className={(errors.oldpin && touched.oldpin ? styles.isInvalid : styles.input)} mask="9999" />
                                </div>
                                <p>Enter new pin <ErrorMessage name='newpin' component={FormErrorMsg} /></p>
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-lock"></i>
                                    </span>
                                    <Field as={InputMask} autoComplete="off" name="newpin" className={(errors.newpin && touched.newpin ? styles.isInvalid : styles.input)} mask="9999" />
                                </div>
                                <p>CONFIRM new pin <ErrorMessage name='cnfpin' component={FormErrorMsg} /></p>
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-lock"></i>
                                    </span>
                                    <Field as={InputMask} autoComplete="off" name="cnfpin" className={(errors.cnfpin && touched.cnfpin ? styles.isInvalid : styles.input)} mask="9999" />
                                </div>
                                <Link to="/waiter-profile"><Button label="BACK TO PROFILE" className={styles.buttonLink} /></Link>
                                <Button label="SUBMIT" className={styles.buttonOutlined} />
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </Fragment>
    )
}
export default NewPin;