import React from 'react';
import { RestaurantSessionStore}  from './RestaurantSessionStore';
import { WaiterSessionStore } from './WaiterSessionStore';



function createRootStore(){
    const restaurantSessionStore = new RestaurantSessionStore()
    const waiterSessionStore = new WaiterSessionStore()

    return {
        waiterSessionStore: waiterSessionStore,
        restaurantSessionStore: restaurantSessionStore
    }
}

const rootStore = createRootStore()

const storeContext = React.createContext(rootStore)

export const useStore = () => React.useContext(storeContext)