import React, { useEffect, useState } from "react";
// import ChevronRight from "./chevron-right.svg";
// import ChevronLeft from "./chevron-left.svg";
import moment from 'moment';
import { useStore } from '../../stores/useStore';
import "./styles.scss";
import { Link } from "react-router-dom";

import { reservations } from '../floor/index'
import { floorList } from '../../Pages/tapAndBook'



const Header = (props) => {


  var [add, setAdd] = useState(0);

  let today = moment().add(add, "days");
  const date = moment(today).format('dddd Do MMMM YYYY');
  const date2 = moment(today).format('yy-MM-DD')



  sessionStorage.setItem("selectedDate", date2)

  const OnAdd = () => {
    if (add < 7) {
      setAdd(++add);
      setTimeout(() => {
        props.TrackHoliday();
        reservations();
        floorList();
      }, 200);
    }
  }
  const OnSub = () => {
    if (add > 0) {
      setAdd(--add);
    }
    setTimeout(() => {
      props.TrackHoliday();
      reservations();
      floorList();
    }, 200);


  }
  const [waiter, setWaiter] = useState();
  const { waiterSessionStore, restaurantSessionStore } = useStore();
  const [restDate, setRestdata] = useState();
  const [sh, setSh] = useState(true);


  const loc = () => {
    if (window.location.pathname === "/") {
      console.log("location of the current page : ", window.location.pathname)
      setSh(false);
    }
    else if (window.location.pathname === '/tap-to-book') {
      console.log("location of the current page : ", window.location.pathname)
      setSh(false);
    }
    else setSh(true);
  }
  useEffect(() => {
    loc();
  }, [])

  useEffect(() => {
    waiterSessionStore.waiterDetails()
      .then(([success, user]) => {
        if (success) {
          let wtr = user.data.first_name + " " + user.data.last_name
          setWaiter(wtr)
          // console.log("Logged in user : ", user.data)
        }
        else console.log("error : ", user)
      })

    restaurantSessionStore.restaurantDetails()
      .then(([success, user]) => {
        if (success) {
          setRestdata(user.restaurant_settings)
          // console.log("Logged in user : ", user.data)
        }
        else console.log("error : ", user)
      })
  }, [])

  return (
    <div className="main-header">
      <img className="restLogo" src={`https://api.mytablefinder.co.uk${restDate?.restaurant_logo}`} alt="restaurant logo" />
      <p className="title">{restDate?.restaurant_name}</p>
      <p className="date">
        <span style={{ display: sh ? "none" : "flex", alignItems: "center" }}>
          <i onClick={OnSub} className="fas fa-caret-left"></i>
          <em>{date}</em>
          <i onClick={OnAdd} className="fas fa-caret-right"></i>
        </span>
      </p>
      <p onClick={() => window.history.go(0)} className="user">
        <Link to="/waiter-profile">
          <b className="username">{waiter}, <span className="role">{localStorage.getItem('user-role')}</span></b>
        </Link>
      </p>
    </div>
  );
};

export default Header;
