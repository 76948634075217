import { action,observable } from 'mobx';
// import moment from 'moment';
import apiClient from './apiClient';

let access = localStorage.getItem('access');

export class RestaurantSessionStore {



    @action
    getReservationList(date:any) {
        return apiClient.get(`/api/v1/reservation/bookings/${localStorage.getItem('rest_id')}&${date}`)
            .then(action((res: any) => {
               
                return [true, res.data]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }

    @action
    getRestaurantSettings() {
        return apiClient.get(`/api/v1/restaurant/settings/${localStorage.getItem('rest_id')}`)
            .then(action((res: any) => {
                return [true, res.data]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }

    @action
    getGuestList() {
        return apiClient.get(`/api/v1/guest/restaurant/${localStorage.getItem('rest_id')}`)
            .then(action((res: any) => {
                return [true, res.data]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }


    @action
    getReservationByStatus(date:any) {
        console.log("api date : ", date)
        return apiClient.get(`/api/v1/reservation/list_reservation_by_date?rest_id=${localStorage.getItem('rest_id')}&booking_date=${date}`)
            .then(action((res: any) => {
                return [true, res.data]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }

    @action
    getWalkinsList(date:any) {
        return apiClient.get(`/api/v1/reservation/walkin/${localStorage.getItem('rest_id')}&${date}`)
            .then(action((res: any) => {
                return [true, res.data]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }
    @action
    deleteWalkin(id:any){
        return apiClient.put(`/api/v1/walkin/delete/${id}`)
        .then(action((res: any) => {
            return [true, res.data]
        }))
        .catch(action(err => {
            let errorMsg = err.response ? err.response.data.detail : err.message
            return [false, errorMsg]
        }))
    }

    @action
    createWalkins(data: any) {
        return apiClient.post('/api/v1/reservation/add_booking_to_queue', data,{
            headers:{
                Authorization: 'Bearer ' + access,
            }
        })
            .then(action((res: any) => {
                return [res.status, res.data]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }

    @action
    bookingFor(data: any) {
        return apiClient.post('/api/v1/reservation/direct_reservation', data,{
            headers:{
                Authorization: 'Bearer ' + access,
            }
        })
            .then(action((res: any) => {
                return [res.status, res.data]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }
    @action
    getOtp(mobile: any) {
        return apiClient.post(`/api/v1/otp/sendotp?mobile_number=${mobile}`,{},{
            headers:{
                Authorization: 'Bearer ' + access,
            }
        })
            .then(action((res: any) => {
                return [true, res.data]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }
    @action
    verifyOtp(mobile: any,otp:any) {
        console.log(mobile,otp)
        return apiClient.post(`/api/v1/otp/validate?mobile=${mobile}&otp=${otp}`,{},{
            headers:{
                Authorization: 'Bearer ' + access,
            }
        })
            .then(action((res: any) => {
                return [true, res.data]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }
    @action
    quickbook(data:any) {
        return apiClient.post(`/api/v1/reservation/quick_book`,data,{
            headers:{
                Authorization: 'Bearer ' + access,
            }
        })
            .then(action((res: any) => {
                return [res.status, res.data]
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }

    @action
    floorOccupency(data:any){
        console.log("sending date : ", data.date)
        return apiClient.get(`/api/v1/reservation/floor_reservations?floor_id=${data.floor_id}&rest_id=${localStorage.getItem('rest_id')}&shift_id=${data.shift_id}&reservation_date=${data.date}`)
        .then(action((res: any) => {
            return [true, res.data]
        }))
        .catch(action(err => {
            let errorMsg = err.response ? err.response.data.detail : err.message
            return [false, errorMsg]
        }))
    }
    @action
    floorPlantb(data:any){
        console.log("sending date : ", data.date)
        return apiClient.get(`/api/v1/floorplan/get/${data.floor_id}`)
        .then(action((res: any) => {
            return [true, res.data]
        }))
        .catch(action(err => {
            let errorMsg = err.response ? err.response.data.detail : err.message
            return [false, errorMsg]
        }))
    }
    @action
    floorPlanData(data:any){
        console.log("sending date : ", data.date)
        return apiClient.get(`/api/v1/floorplan/floor_plan_reservations?date=${data.date}&shift_id=${data.shift_id}&floor_id=${data.floor_id}`)
        .then(action((res: any) => {
            return [true, res.data]
        }))
        .catch(action(err => {
            let errorMsg = err.response ? err.response.data.detail : err.message
            return [false, errorMsg]
        }))
    }

    @action
    floorList(){
        return apiClient.get(`/api/v1/floor/list/${localStorage.getItem('rest_id')}`)
        .then(action((res: any) => {
            return [true, res.data]
        }))
        .catch(action(err => {
            let errorMsg = err.response ? err.response.data.detail : err.message
            return [false, errorMsg]
        }))
    }
    @action
    shiftList(){
        return apiClient.get(`/api/v1/shift/list?rest_id=${localStorage.getItem('rest_id')}`)
        .then(action((res: any) => {
            return [true, res.data]
        }))
        .catch(action(err => {
            let errorMsg = err.response ? err.response.data.detail : err.message
            return [false, errorMsg]
        }))
    }
    @action
    changeShift(data:any){
        return apiClient.put(`/api/v1/reservation/update_reservation_status?reservation_id=${data.reservation_id}&status_id=${data.status_id}`,{}, {
            headers:{
                Authorization: 'Bearer ' + access,
            }
        })
        .then(action((res: any) => {
            return [true, res.data]
        }))
        .catch(action(err => {
            console.log("changeshift error ",err);
            let errorMsg = err.response ? err.response.data.detail : err.message
            return [false, errorMsg]
        }))
    }

    @action
    updateTime(data:any){
        return apiClient.put(`/api/v1/reservation/update_reservation_time?reservation_id=${data.id}&extra_time=${data.time}`)
        .then(action((res: any) => {
            return [true, res.data]
        }))
        .catch(action(err => {
            let errorMsg = err.response ? err.response.data.detail : err.message
            return [false, errorMsg]
        }))
    }

    @action
    restaurantDetails(){
        return apiClient.get(`/api/v1/restaurant/settings/${localStorage.getItem('rest_id')}`)
        .then(action((res: any) => {
            return [true, res.data]
        }))
        .catch(action(err => {
            let errorMsg = err.response ? err.response.data.detail : err.message
            return [false, errorMsg]
        }))
    }
    @action
    holidaysList(){
        return apiClient.get(`/api/v1/restaurant_settings/time_availability/holiday/${localStorage.getItem('rest_id')}`)
        .then(action((res: any) => {
            return [true, res.data]
        }))
        .catch(action(err => {
            let errorMsg = err.response ? err.response.data.detail : err.message
            return [false, errorMsg]
        }))
    }
}