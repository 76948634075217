import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from "react-router";
import { Dropdown } from 'primereact/dropdown';
import moment from 'moment';
import './tapAndBook.scss';
import { Calendar } from 'primereact/calendar';
import axios from 'axios';
import { Dialog } from 'primereact/dialog';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { InputNumber } from 'primereact/inputnumber';
import { InputMask } from 'primereact/inputmask';
import { InputText } from "primereact/inputtext";
import OtpInput from "react-otp-input";
import * as yup from 'yup';
import styled from 'styled-components';
import { useStore } from "../stores/useStore";
import { toast } from 'react-toastify';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Loader from '../Components/Loader/loader'

const FormErrorMsg = styled.span`
 color: red;
 float:right;
 font-size:12px;
`

const TapAndBook: React.FC<any> = (props) => {
    const history = useHistory();
    const [loader, setLoader] = useState(false);

    const [floors, setFloors] = useState<any>();
    const [shifts, setShifts] = useState<any>();
    const selectedDate = sessionStorage.getItem("selectedDate");
    const [dialog1, setDialog1] = useState(false);
    const [from, setFrom] = useState("");
    const [to, setTo] = useState();
    const [selectedFloor, setSelectedFloor] = useState<any>();
    const [selectedShift, setSelectedShift] = useState<any>();
    const { restaurantSessionStore } = useStore();
    const onHide = () => {
        setDialog1(false);
    }
    const floorList = () => {
        restaurantSessionStore.floorList()
            .then(([success, data]) => {
                if (success) {
                    let floors = data.floors_list.filter((dlt: any) => dlt.is_deleted === false)
                    setFloors(floors)
                    setSelectedFloor(floors[0])
                    console.log("floor list success : ", floors)
                    toast.success(floors)
                    // document.getElementById('shiftClick')!.click();
                } else {
                    toast.error(data)
                    console.log("floor list error : ", data)
                }
            })
    }
    const shiftList = () => {
        restaurantSessionStore.shiftList()
            .then(([success, data]) => {
                if (success) {
                    let shifts = data.filter((dlt: any) => dlt.is_deleted === false)
                    setShifts(shifts)
                    setSelectedShift(shifts[0])
                    toast.success(shifts)
                    console.log("shift list success : ", shifts)

                } else {

                    toast.error(data)
                    console.log("shift list error : ", data)
                }
            })
    }
    const [floorData, setFloorData] = useState([]);
    const [floorTime, setFloorTime] = useState<any>();
    const [show, setShow] = useState(false);
    const onShiftchange = (e: any) => {
        setSelectedShift(e.value);
    }
    const onFloorChange = (e: any) => {
        setSelectedFloor(e.value);
    }
    const [talias, setAlias] = useState([]);
    const floorTablesDetails = async () => {
        const data = {
            "floor_id": selectedFloor?.floor_id,
            "shift_id": selectedShift?.id,
            "date": moment(selectedDate).format('yy-MM-DD')
        }
        await restaurantSessionStore.floorOccupency(data)
            .then(([success, data]) => {
                if (success) {
                    if ((data?.TableData.length) > 0) {
                        setFloorData(data?.TableData)
                        console.log("floorOccupency data ss : ", data?.TableData)
                    }
                    else {
                        console.log("floorOccupency data fl : ", data?.TableData)
                        setFloorData([])
                    }
                    setFloorTime(data.Shift_info)
                    console.log("shift info : ", data.Shift_info)
                    setShow(true);
                    // console.log("floorOccupency success : ", tbls)
                } else {
                    setFloorData([])
                    console.log("floorOccupency error : ", data)
                }
            })

        await restaurantSessionStore.floorPlantb(data)
            .then(([success, data]) => {
                if (success) {
                    let ff = data.filter((s: any) => s.is_deleted_floor_plan === false)
                    setAlias(ff)
                    console.log("floorplantb success : ", ff)
                } else {
                    console.log("floorplantb error : ", data)
                }
            })


    }
    //floor timeings
    // let gap:number = (floorTime?.default_booking_period)
    var x = {
        nextSlot: (floorTime?.default_booking_period + (floorTime?.duration_btn_bookings !== (undefined && 0)?floorTime?.duration_btn_bookings:0)),
        // nextSlot: gap,
        startTime: floorTime?.start_time,
        endTime: floorTime?.end_time
    };

    var slotTime = moment(x.startTime, "HH:mm");
    var endTime = moment(x.endTime, "HH:mm");

    let times = [];
    while (slotTime < endTime) {
        times.push({ "time": slotTime.format("HH:mm"), "interval": x.nextSlot });
        slotTime = slotTime.add(x.nextSlot, 'minutes');
    }

    useEffect(() => {
        shiftList();
        floorList();
        // setInterval(()=>floorTablesDetails(),1000*300)
        floorTablesDetails();
    }, [])

    const [guestCount, setGuestCount] = useState(1);
    const GetGuest = (e: any) => {
        if (guestCount >= 1) {
            setGuestCount(e.value)
        }
    }

    const [selectedTable, setSelectedTable] = useState<any>();
    const SubmitValues = (values: any) => {
        setLoader(true)
        let valu =
        {
            first_name: values.fname,
            last_name: values.lname,
            mobile: values.phone,
            email:values.email,
            shift_id: selectedShift.id,
            rest_id: localStorage.getItem('rest_id'),
            booked_date: moment(selectedDate).format('YYYY-MM-DD'),
            no_of_guests: guestCount,
            reserved_from: moment(from,"hh:mm a").format('HH:mm:ss'),
            reserved_to: "21:00:00",
            floor_plan_id: selectedTable?.floor_plan_id
        }
        restaurantSessionStore.bookingFor(valu)
            .then(([success, message]: any) => {
                if (success) {
                    setGuestCount(1);
                    setFrom("");
                    setMobile("");
                    setOtp2(false);
                    floorTablesDetails();
                    setHideotp(false);
                    toast.success("Customer Reservation Successfull")
        setLoader(false)
                    console.log("booking created : ", message);
                } else {
                    setFrom("");
                    setMobile("");
                    setOtp2(false);
                    setHideotp(false);
                    toast.error(message)
        setLoader(false)
                    console.log("not working : ", message)
                }
            })
        console.log(valu,selectedTable);
        onHide();
    }
    const [mobile, setMobile] = useState("");

    const getOtp = () => {
        if (mobile.length === 13) {
            console.log("otp mobile", mobile)
            const num = mobile.replace("+", "%2B")
            console.log("otp mobile", num)
            restaurantSessionStore.getOtp(num)
                .then(([success, message]) => {
                    if (success) {

                        toast.success(message.data)
                        console.log("otp : ", message.data);
                    } else {
                        toast.error(message)
                        console.log("otp : ", message)
                    }
                })
        }
    }

    const [otp2, setOtp2] = useState(false);
    const [add, setAdd] = useState(true);
    const [hideotp,setHideotp]=useState(false);
    const verifyOtp = (e: any) => {
        setOtp2(e);
        console.log(e)
        if (e.length === 6) {
            const num = mobile.replace("+", "%2B")
            restaurantSessionStore.verifyOtp(num, e)
                .then(([success, message]) => {
                    if (success) {
                        setAdd(false);
                        setHideotp(false)
                        toast.success(message);
                        console.log("otp verification : ", message);
                    } else {

                        toast.error(message)
                        console.log("otp verification error : ", message)

                    }
                })
        }
    }


    return <Fragment>
      <Loader setLoader={setLoader} visible={loader} />

        <div
            className="timehead">
            <i onClick={() => history.push('/')} className="fas fa-arrow-circle-left"></i>

            <Dropdown onFocus={floorTablesDetails} style={{ width: '150px', marginRight: '15px', textAlign: 'center' }} value={selectedShift} options={shifts} onChange={(e: any) => { onShiftchange(e); }} optionLabel="name" placeholder="Select Shift" />
            <Dropdown onFocus={floorTablesDetails} style={{ width: '150px', textAlign: 'center' }} value={selectedFloor} options={floors} onChange={(e: any) => { onFloorChange(e); }} optionLabel="name" placeholder="Select Floor" />
            <button id="shiftClick" onClick={() => { floorTablesDetails();}} style={{ opacity: "0" }}>.</button>
        </div>
        {show ?
            <div className="tablesList">
                <div className="time">
                    <ul>
                        {times.map((time: any) => (
                            <li key={time.time} style={{textTransform:"uppercase", height:`${time.interval}px`}}
                            //  className={`h${time.interval - floorTime?.duration_btn_bookings}`}
                              >{moment(time.time,"HH:mm:ss").local().format("hh:mm a")}</li>
                        ))}
                    </ul>
                </div>
                <div className="tables">
                    <div className="tablehh">
                        {talias?.map((table: any) => (
                            <div className="tableh">
                                <h3 style={{backgroundColor:table.is_online?"#e3faf0":"#e7e0f7",color: table.is_online?"#000":"#510AEA",}} key={table?.plan_id}>{table.alias === "null" ? table.table_type_name : table.alias} <span style={{ float: "right" }}>{table.seating_capacity} <span className="fas fa-chair"></span></span></h3>
                            </div>
                        ))}
                    </div>

                    {floorData?.map((table: any) => (
                        <div key={table.floor_plan_id} onClick={() => { setDialog1(true); setSelectedTable(table);console.log(table) }} className="table">
                            <div style={{ position: "relative" }}>
                                {table.bookings.map((tstatus: any) => (
                                    <div onClick={(e) => { e.stopPropagation() }} key={tstatus.booking_id} style={{ position: "absolute", top: `${tstatus.time_difference}px`,height:`${(tstatus.time_interval / (-60))+ floorTime?.duration_btn_bookings}px` }} className={tstatus.status}>
                                        <p className="tbStatus">{tstatus.status === "Vacant" ? null : tstatus.status}</p>
                                        <h6 className="tbmsg">{tstatus.tg}</h6>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))
                    }
                </div>
            </div> :
            <div className="nodata">
                <p>select a shift &amp; floor from above options to see data here.</p>
                <img src="images/nodata.png" alt="nodata" />
            </div>
        }
        <Dialog visible={dialog1} position="center" style={{ width: "350px", height: "auto" }} onHide={() => onHide()}>
            <Formik
                initialValues={{ fname: '', lname: '',email:"", phone: "", walkin: "", fromtime: "" }}
                validationSchema={yup.object({
                    fname: yup.string()
                        .required('Required'),
                    lname: yup.string()
                        .required('Required'),

                        email:yup.string().email('Must be a valid email').max(255).required('Email is required'),

                    // phone: yup.string()
                    //     .required('Required'),
                    // fromtime: yup.string()
                    // .required('Required'),
                })}
                onSubmit={(values) => {
                    values.phone = mobile;
                    console.log(values); SubmitValues(values);
                }}>
                {({ errors, status, touched }) => (
                    <Form autoComplete="off">
                        <div className="walkingQueue">
                            <div className="queueForm">
                                <div className='queueOne'>
                                    <h5 style={{ marginBottom: '10px', color: '#510AEA',textTransform:"uppercase" }}>BOOKING FOR TABLE :&nbsp;{selectedTable.floor_plan_alias==="null"?selectedTable.table_name:selectedTable.floor_plan_alias}<span onClick={() => { onHide(); setGuestCount(1); setFrom("");setMobile("") }}><i className="pi pi-times close"></i></span>
                                    </h5>
                                    <hr />
                                    <br />
                                    <label >First Name<ErrorMessage name='fname' component={FormErrorMsg} /></label>
                                    <div className="p-inputgroup hndl-mr">
                                        <Field style={{ marginTop: '5px', marginBottom: '15px' }} as={InputText} name="fname" className={(errors.fname && touched.fname ? ' is-invalid' : 'p-inputgroup hndl-mr')} />
                                    </div>
                                    <label >Last Name<ErrorMessage name='lname' component={FormErrorMsg} /></label>
                                    <div className="p-inputgroup hndl-mr">
                                        <Field style={{ marginTop: '5px', marginBottom: '15px' }} as={InputText} name="lname" className={(errors.lname && touched.lname ? ' is-invalid' : 'p-inputgroup hndl-mr')} />
                                    </div>
                                    <label >Email<ErrorMessage name='email' component={FormErrorMsg} /></label>
                    <div className="p-inputgroup hndl-mr">
                      <Field required style={{ marginTop: '5px', marginBottom: '15px' }} as={InputText} name="email" className={(errors.email && touched.email ? ' is-invalid' : 'p-inputgroup hndl-mr')} />
                    </div>
                                    <label style={{ marginTop: '15px' }} >Number of Guests<ErrorMessage name='walkin' component={FormErrorMsg} /></label>
                                    <div className="p-inputgroup hndl-mr">
                                        <InputNumber style={{ marginTop: '10px', marginBottom: '15px' }} min={1} max={selectedTable.table_id.seating_capacity} id="horizontal" name="walkin" className="numinc" showButtons buttonLayout="horizontal"
                                            decrementButtonClassName="p-inpnumber-button" incrementButtonClassName="p-inpnumber-button" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" value={guestCount} onChange={(e: any) => GetGuest(e)} />
                                        {/* <Field  style={{marginTop:'5px',marginBottom:'10px'}} as={InputText} name="name"  className={ (errors.name && touched.name ? ' is-invalid' : 'p-inputgroup hndl-mr') } /> */}
                                    </div>
                                    <div style={{ display: "flex", columnGap: "20px" }}>
                                        <div>
                                            <label>From</label><br />
                                            <Field as={InputMask} required mask="99:99 aa" placeholder="Ex: 01:00 AM" name="fromtime" value={from} onChange={(e: any) => { setFrom(e.value) }} />
                                        </div>
                                        {/* <div>
                                            <label>To</label><br />
                                            <Calendar id="time12" name="totime" inline value={to} onChange={(e: any) => setTo(e.value)} timeOnly hourFormat="12" />
                                        </div> */}
                                    </div>
                                    <br/>
                                    <label style={{ marginTop: '15px' }}>Phone Number<span id="phone"
                                        style={{ display: "none", float: "right", fontSize: "14px" }}
                                        className="float-right">Required</span></label>
                                    <div id="pinp"  className="p-inputgroup gotp hndl-mr code">
                                        <Field value={mobile} onChange={(e: any) => {
                                            if (e?.length <= 11) {
                                                let p = document.getElementById('phone')
                                                let pi = document.getElementById('pinp')
                                                p.style.color = "red"
                                                p.style.display = "initial"
                                                pi.style.border = "1px solid red"
                                            }
                                            else {
                                                let p = document.getElementById('phone')
                                                let pi = document.getElementById('pinp')
                                                p.style.display = "none"
                                                pi.style.border = "none"

                                            }
                                            setMobile('+' + e)
                                            // if(mobile.length===13){
                                            //     setHideotp(true)
                                            // }
                                            // else setHideotp(false)
                                            }} 
                                            onBlur={(e:any) => {
                                                console.log("on blur phone", e.target.value)
                                                if (e.target.value?.length <= 3) {
                                                    let p = document.getElementById('phone')
                                                    let pi = document.getElementById('pinp')
                                                    p.style.color = "red"
                                                    p.style.display = "initial"
                                                    pi.style.border = "1px solid red"
                                                }
                                                else {
                                                    let p = document.getElementById('phone')
                                                    let pi = document.getElementById('pinp')
                                                    p.style.display = "none"
                                                    pi.style.border = "none"

                                                }
                                            }}
                                            style={{ marginTop: '5px', marginBottom: '15px' }} required as={PhoneInput} type="tel" onlyCountries={['gb', 'in']}
                                            country={'gb'}  name="phone" className={(errors.phone && touched.phone ? ' is-invalid' : 'input getotp')} />
                                        {/* {hideotp?
                                        <span onClick={getOtp} className='p-inputgroup-addon'>
                                            Get OTP
                                        </span>:null} */}
                                    </div>
                                    {/* {hideotp?<>
                                    <label style={{ marginTop: '15px' }}>OTP</label>
                                    <div className="p-inputgroup hndl-mr">
                                        <Field
                                            as={OtpInput}
                                            value={otp2}
                                            onChange={(e: any) => { verifyOtp(e) }}
                                            numInputs={6}
                                            maxLimit={6}
                                            // className={(errors.otp && touched.otp ? ' is-invalidinp' : 'otpField')}
                                            className='otpField'
                                            separator={<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>}
                                        />
                                    </div></>:null
                                    } */}
                                    <br/>
                                    <input 
                                    // disabled={add}
                                     type="submit" value="Add" className='walkin-add-btn' />
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </Dialog>
    </Fragment>
}
export default TapAndBook


export const floorList = async () => {
    console.log("current url : ", window.location.pathname)
    if (window.location.pathname === '/tap-to-book') {
        let clk = document.getElementById('shiftClick')!
        clk.click();
        setTimeout(() => {
            clk.click();
        }, 300);

        console.log("clicked", clk)
    }
 }