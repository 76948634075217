import { action, computed, observable } from 'mobx';
// import moment from 'moment';
import apiClient from './apiClient';

let access = localStorage.getItem('access');

export class WaiterSessionStore {
    @observable
    user: any = localStorage.getItem('user-role')
    id: any =  localStorage.getItem('role-id')
    @computed
    get isAuthenticated(){
        return this.user
    }
    
    @action
    authenticate(username: string, password: string) {
        let user = {'username':username,'password':password}
        console.log("login data post : ", user)
        return apiClient.post('/api/v1/login', user)
            .then(action((res: any) => {
                console.log(res)
                if (res.data.access_token) {
                    localStorage.setItem('access', res.data.access_token)
                    localStorage.setItem('refresh', res.data.refresh_token)
                    localStorage.setItem('user-role',res.data.user_role)
                    localStorage.setItem('role-id',res.data.role_id)
                    localStorage.setItem('rest_name',res.data.restaurant_name)
                    localStorage.setItem('rest_id',res.data.restaurant_id)
                    
                }
                return res.data
            }))
            .then(action((res: any) => {
                if(res.user_role){
                    this.user = res.user_role
                    this.id = res.user_id
                    return [true, res]
                }
            }))
            .catch(action(err => {
                let errorMsg = err.response ? err.response.data.detail : err.message
                return [false, errorMsg]
            }))
    }

    @action
    logout() {
        localStorage.clear();
        sessionStorage.clear();
        this.user = null
    }

    @action
    waiterDetails(){
        return apiClient.get(`/api/v1/waiter/details/${this.id}`)
        .then(action((res)=>{
            return [true, res]
        }))
        .catch(action((err)=>{
            let errorMsg = err.response ? err.response.data.detail : err.message
            console.log("error : ",errorMsg)
                return [false, errorMsg]
        }))
    }

    @action
    waiterRestPin(mobile:any){
        return apiClient.post('/api/v1/waiter/reset_pin',mobile)
        .then(action((res)=>{
            return [true, res]
        }))
        .catch(action((err)=>{
            let errorMsg = err.response ? err.response.data.detail : err.message
            console.log("error : ",errorMsg)
                return [false, errorMsg]
        }))
    }
    @action
    waiterChangePin(data:any){
        return apiClient.post('/api/v1/change-password',data, {
            headers:{
                Authorization: 'Bearer ' + access,
            }
        })
        .then(action((res)=>{
            return [true, res]
        }))
        .catch(action((err)=>{
            let errorMsg = err.response ? err.response.data.detail : err.message
            console.log("error : ",errorMsg)
                return [false, errorMsg]
        }))
    }


}