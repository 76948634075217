import React ,{ Fragment } from 'react';
import './reservationStatus.css';


const ReservationStatus = () => {
    return <Fragment>
        <div className='resStatus'>
            <div className='detailsHolder'>
                <div className='detailsCard'>
                    
                    <h5>RESERVATION STATUS</h5>
                    <span><i className="pi pi-times close"></i></span>
                    <hr />
                    <h3 style={{fontSize:'14px',color:'#393939',fontWeight:'700'}}>
                        Isha Mosley
                   </h3>
                    <p style={{textAlign:'center',marginTop:'5px',fontSize:'12px',fontWeight:'400',color:'#747474'}}>T3,9:00 A.M,SAT,21 DEC 2020.
                   </p>
<div className='statusBtn'>

                    <button  className=' status' >Confirmed</button>
                    <button className='status'>Not Confirmed</button>

                    <button className='status'>Running Late</button>

                    <button className='status'>No Show</button>
                    <button className='status'>Seated</button>
                    <button className='status'>Cancelled</button>

                   
                  
                    {/* <hr /> */}
                  
                    </div>
                   


                </div>
            </div>
        </div>
    </Fragment>}

    export default ReservationStatus;