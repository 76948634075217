import React, { Fragment } from 'react';
import "./seatedReservartionDetails.css";


const PopUp3 = () => {
    return <Fragment>
           <div className="popUp3">
               <div className="popUp3-card">
               <h5>RESERVATION DETAILS</h5>
                    <i className="pi pi-times close"></i>
                    <hr />

                    <div className="childOne">
                    <h3>
                        Isha Mosley
                   </h3>
                    <p>T3,9:00 A.M,SAT,21 DEC 2020.
                   </p>
                    </div>

                    <div className="childTwo">
                        <p>Status</p>
                    <button  className='status-btn' >Occupied<i className="pi pi-play" style={{color:'white',marginLeft:'210px',marginTop:'0px',fontSize:'9px'}}></i></button>
                    <p>Set Occupancy Time [min]</p>

                    </div>
                    <div className="occupancyTime">

                        <p>30</p>
                        <p>45</p>
                        <p>60</p>
        

                    </div>

                    <hr/>


                    <div className="done-btn">
                    <button className='dn-btn'>DONE</button>

                    </div>

                    <div className="cancel">
                    <p>Cancel Reservation</p>
                        <p>Reset</p>  

                    </div>
               </div>


           </div>
    </Fragment>
}



export default PopUp3;